// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-useless-computed-key */
import { createTheme } from '@material-ui/core/styles';
import { defaultTheme } from 'react-admin';
import * as colors from '@material-ui/core/colors';

// здесь можем создать кастомные цвета для темы в будущем

const newColorsRGBA = {
  purple: 'rgba(124, 77, 255, {opacity})',
  grey: 'rgba(51, 51, 51, {opacity})',
};

const getNewColor = (opacity, color) => {
  return newColorsRGBA[color].replace('{opacity}', opacity);
};

export const customTheme = createTheme({
  ...defaultTheme,
  breakpoints: {
    values: {
      sm: 1024,
    },
  },
  customPalette: {
    main: colors.deepPurple[500],
    mainBackground: colors.deepPurple[50],
    mainBackgroundHover: colors.deepPurple[100],
  },
  palette: {
    primary: colors.deepPurple,
    secondary: colors.indigo,
    primaryNew: {
      0.24: getNewColor(0.24, 'purple'),
      0.7: getNewColor(0.7, 'purple'),
      main: getNewColor(1, 'purple'),
      background: colors.deepPurple[50],
      light: colors.deepPurple[50],
      light100: colors.deepPurple[100],
      accentFont: colors.deepPurple[500],
      contrastText: '#fff',
    },
    grey: {
      main: getNewColor(1, 'grey'),
      light: colors.grey[100],
      fadedFont: colors.grey[700],
      0.065: getNewColor(0.065, 'grey'),
      background: '#FAFAFA',
      bgCard: 'rgba(0, 0, 0, 0.04);',
    },
    red: {
      background: colors.red[50],
      light: colors.red[50],
      light100: colors.red[100],
      accentFont: colors.red[500],
      bgCard: 'rgba(255, 4, 4, 0.12)',
    },
    green: {
      background: colors.green[50],
      light: colors.green[50],
      light100: colors.green[100],
      accentFont: colors.green[500],
      bgCard: 'rgba(73, 180, 109, 0.24)',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    common: {
      white: '#fff',
    },
  },
  shape: {
    borderRadius: 4,
  },
  sidebar: {
    closedWidth: 56,
  },
  overrides: {
    MuiTabs: {
      root: {
        background: '#fff',
      },
    },
    MuiTab: {
      root: {
        minWidth: '0 !important',
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: 14,
        marginRight: 14,
      },
    },
    RaSidebar: {
      root: {
        height: '100%',
      },
      fixed: {
        width: 'inherit',
      },
    },
    RaMenuItemLink: {
      root: {
        '&:hover': {
          background: colors.deepPurple[50],
          color: colors.deepPurple.A200,
        },

        '&:hover *': {
          color: colors.deepPurple.A200,
        },

        '&.Mui-selected': {
          background: colors.deepPurple[50],
          color: colors.deepPurple.A200,
        },

        '&.Mui-selected:hover': {
          background: colors.deepPurple[50],
          color: colors.deepPurple.A200,
        },
      },
      active: {
        background: colors.deepPurple[50],
        color: colors.deepPurple.A200,

        '& svg': {
          color: colors.deepPurple.A200,
        },
      },
    },
    RaLayout: {
      root: {
        backgroundColor: '#fff',
        minWidth: 'auto',
        paddingTop: 48,
        boxSizing: 'border-box',
      },
      content: {
        minWidth: '0',
        flex: '1 1 auto',
        backgroundColor: '#fff',
        padding: '12px !important',
        paddingBottom: '0px !important',
      },
      appFrame: {
        marginTop: '0 !important',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        paddingLeft: 8,
        color: '#000',
        backgroundColor: '#fff',
      },
      root: {
        transform: 'none !important',
        visibility: 'visible !important',
      },
    },
    RaAppBar: {
      toolbar: {
        minHeight: 48,
      },
    },
    RaDatagrid: {
      headerCell: {
        zIndex: 10,
      },
    },
    RaEdit: {
      root: {
        height: '100%',
        '& .tabbed-form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
        '& .RaToolbar': {
          marginTop: 0,
        },
      },
      main: { height: '100%' },
      card: {
        height: '100%',
        backgroundColor: 'rgb(250, 250, 250)',
      },
      noActions: {
        marginTop: 0,
      },
    },
    MuiDialogContent: {
      root: {
        height: '100%',

        '& .tabbed-form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      },
    },
    MuiCardContent: {
      root: {
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: 840,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    RaTabbedForm: {
      content: {
        flex: '1 1 0',
        minHeight: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 840,
        position: 'relative',
        width: '100%',
      },
      errorTabButton: {
        color: '#f44336 !important',
      },
    },
    RaCardContentInner: {
      root: {
        paddingBottom: 16,
      },
    },
    RaCreate: {
      root: {
        height: '100%',
        '& .tabbed-form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      },
      main: { height: '100%' },
      card: {
        height: '100%',
        backgroundColor: 'rgb(250, 250, 250)',
      },
      noActions: {
        marginTop: '0 !important',
      },
    },
    RaToolbar: {
      spacer: {
        ['@media (min-width: 0)']: {
          height: 'auto',
        },
      },
      mobileToolbar: {
        position: 'relative',
        padding: '15px !important',
      },
    },
    RaFormInput: {
      input: {
        width: '100%',
        boxSizing: 'border-box',
      },
    },
    RaSimpleFormIterator: {
      form: {
        padding: '12px 0',

        '& .ra-input': {
          marginBottom: '12px',

          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },
  },
  props: {
    MuiTextField: {},
    MuiButton: {
      disableElevation: true,
    },
  },
});

export const bitrixTheme = createTheme({
  ...defaultTheme,
  breakpoints: {
    values: {
      sm: 1024,
    },
  },
  customPalette: {
    main: colors.blueGrey[500],
    mainBackground: colors.blueGrey[50],
    mainBackgroundHover: colors.blueGrey[100],
  },
  palette: {
    primary: colors.blueGrey,
    secondary: colors.indigo,
    primaryNew: {
      0.24: getNewColor(0.24, 'grey'),
      0.7: getNewColor(0.7, 'grey'),
      main: getNewColor(1, 'grey'),
      background: colors.blueGrey[50],
      light: colors.blueGrey[50],
      light100: colors.blueGrey[100],
      accentFont: colors.blueGrey[500],
      contrastText: '#fff',
    },
    grey: {
      main: getNewColor(1, 'grey'),
      light: colors.grey[100],
      fadedFont: colors.grey[700],
      0.065: getNewColor(0.065, 'grey'),
      background: '#FAFAFA',
      bgCard: 'rgba(0, 0, 0, 0.04);',
    },
    red: {
      background: colors.red[50],
      light: colors.red[50],
      light100: colors.red[100],
      accentFont: colors.red[500],
      bgCard: 'rgba(255, 4, 4, 0.12)',
    },
    green: {
      background: colors.green[50],
      light: colors.green[50],
      light100: colors.green[100],
      accentFont: colors.green[500],
      bgCard: 'rgba(73, 180, 109, 0.24)',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    common: {
      white: '#fff',
    },
  },
  shape: {
    borderRadius: 4,
  },
  sidebar: {
    closedWidth: 56,
  },
  overrides: {
    MuiTabs: {
      root: {
        background: '#fff',
      },
    },
    MuiTab: {
      root: {
        minWidth: '0 !important',
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: 14,
        marginRight: 14,
      },
    },
    RaSidebar: {
      root: {
        height: '100%',
      },
      fixed: {
        width: 'inherit',
      },
    },
    RaMenuItemLink: {
      root: {
        '&:hover': {
          background: colors.deepPurple[50],
          color: colors.deepPurple.A200,
        },

        '&:hover *': {
          color: colors.deepPurple.A200,
        },

        '&.Mui-selected': {
          background: colors.deepPurple[50],
          color: colors.deepPurple.A200,
        },

        '&.Mui-selected:hover': {
          background: colors.deepPurple[50],
          color: colors.deepPurple.A200,
        },
      },
      active: {
        background: colors.deepPurple[50],
        color: colors.deepPurple.A200,

        '& svg': {
          color: colors.deepPurple.A200,
        },
      },
    },
    RaLayout: {
      root: {
        backgroundColor: '#fff',
        minWidth: 'auto',
        paddingTop: 48,
        boxSizing: 'border-box',
      },
      content: {
        minWidth: '0',
        flex: '1 1 auto',
        backgroundColor: '#fff',
        padding: '12px !important',
        paddingBottom: '0px !important',
      },
      appFrame: {
        marginTop: '0 !important',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        paddingLeft: 8,
        color: '#000',
        backgroundColor: '#fff',
      },
      root: {
        transform: 'none !important',
        visibility: 'visible !important',
      },
    },
    RaAppBar: {
      toolbar: {
        minHeight: 48,
      },
    },
    RaDatagrid: {
      headerCell: {
        zIndex: 10,
      },
    },
    RaEdit: {
      root: {
        height: '100%',
        '& .tabbed-form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
        '& .RaToolbar': {
          marginTop: 0,
        },
      },
      main: { height: '100%' },
      card: {
        height: '100%',
        backgroundColor: 'rgb(250, 250, 250)',
      },
      noActions: {
        marginTop: 0,
      },
    },
    MuiDialogContent: {
      root: {
        height: '100%',

        '& .tabbed-form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      },
    },
    MuiCardContent: {
      root: {
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: 840,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    RaTabbedForm: {
      content: {
        flex: '1 1 0',
        minHeight: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 840,
        position: 'relative',
        width: '100%',
      },
      errorTabButton: {
        color: '#f44336 !important',
      },
    },
    RaCardContentInner: {
      root: {
        paddingBottom: 16,
      },
    },
    RaCreate: {
      root: {
        height: '100%',
        '& .tabbed-form': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      },
      main: { height: '100%' },
      card: {
        height: '100%',
        backgroundColor: 'rgb(250, 250, 250)',
      },
      noActions: {
        marginTop: '0 !important',
      },
    },
    RaToolbar: {
      spacer: {
        ['@media (min-width: 0)']: {
          height: 'auto',
        },
      },
      mobileToolbar: {
        position: 'relative',
        padding: '15px !important',
      },
    },
    RaFormInput: {
      input: {
        width: '100%',
        boxSizing: 'border-box',
      },
    },
    RaSimpleFormIterator: {
      form: {
        padding: '12px 0',

        '& .ra-input': {
          marginBottom: '12px',

          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },
  },
  props: {
    MuiTextField: {},
    MuiButton: {
      disableElevation: true,
    },
  },
});
