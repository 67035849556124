import React, { useCallback, useMemo, useRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { browserHistory } from '../../../../App';
import { DefaultSelectedMenu } from '../Default';
import { ObjectsMultipleDialog } from '../../../dialogs';
import { dataSelector } from '../../../../redux';

const checkCorrectObject = (item) => {
  const isSold =
    Number(item.funnelStep) === 600 ||
    Number(item.funnelStep) === 700 ||
    Number(item.funnelStep) === 900 ||
    Number(item.funnelStep) === 1000 ||
    Number(item.funnelStep) === 1100;

  return (
    item.sysStatus === 200 && !isSold && (item.currentPrice >= 0 || item.currentRentPrice >= 0)
  );
};

export const ObjectsSelectedMenu = ({ onClose, selectedIds, resource, permissions }) => {
  const reduxData = useSelector(dataSelector(resource));

  const selectedItems = useMemo(() => {
    const items = [];

    selectedIds.forEach((id, index) => {
      const item = reduxData[id];

      if (item && checkCorrectObject(item)) {
        items.push({
          id: `client:${new Date().getTime()}${index}`,
          realtyObject: item,
          realtyObjectId: item.id,
          name: '.',
          sortOrder: 1000,
          clientStatus: 'create',
        });
      }
    });

    return items;
  }, [reduxData, selectedIds]);

  const handleCreateSet = useCallback(() => {
    browserHistory.push('/RealtyObjectSets/create', { items: selectedItems });
  }, [selectedItems]);

  const handleCreateFeed = useCallback(() => {
    browserHistory.push('/RealtyFeeds/create', { items: selectedItems });
  }, [selectedItems]);

  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    changeOpenRef.current(true);

    onClose();
  }, [onClose]);

  return (
    <>
      <ObjectsMultipleDialog
        changeOpenRef={changeOpenRef}
        selectedIds={selectedIds}
        data={reduxData}
        resource={resource}
        permissions={permissions}
      />
      <DefaultSelectedMenu resource={resource} selectedIds={selectedIds} onClose={onClose} />
      <MenuItem onClick={handleCreateSet}>Добавить в подборку</MenuItem>
      <MenuItem onClick={handleCreateFeed}>Добавить в фид</MenuItem>
      <MenuItem onClick={handleOpenDialog}>Редактировать поля</MenuItem>
    </>
  );
};
