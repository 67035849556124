import React, { useMemo } from 'react';

import { checkRole } from '../../../utils';
import { roleFlags } from '../../../constants';
import { currentUser } from '../../../authProvider';

import { PeopleSelect } from '../People';

export const ExpertSelect = (props) => {
  const { id, permissions, label, componentProps = {} } = props;

  const defaultExpert = useMemo(() => {
    if (!id && checkRole(permissions, roleFlags.isExpert)) {
      return currentUser;
    }

    return null;
  }, [id, permissions]);

  return (
    <PeopleSelect
      source="manager"
      resourceId="managerId"
      {...props}
      componentProps={{
        ...componentProps,
        label: label || 'Эксперт',
        defaultValue: defaultExpert,
        providerParams: { filter: { isExpert: true, FlatDetails: 300 } },
      }}
    />
  );
};
