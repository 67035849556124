import React, { useEffect } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import { currentProfile } from '../../../authProvider';

import { FormObjectsB24 } from '../../../components/forms';
import { Edit } from '../../../components/common';
import { bitrixTheme } from '../../../theme';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .tabbed-form': {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      flexWrap: 'wrap',

      '& > div:nth-of-type(2) > div': {
        marginLeft: 0,
        marginRight: 0,
      },

      '& > div:nth-of-type(3)': {
        width: '100%',
      },
    },
  },
}));

export const EditObjectsB24 = (props) => {
  const classes = useStyles();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const firstEntry = Object.values(entries)[0];
      const height =
        firstEntry.borderBoxSize[0].blockSize ||
        firstEntry.contentBoxSize[0].blockSize ||
        firstEntry.contentRect.height;

      window.parent.postMessage(String(height), '*');
    });

    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <ThemeProvider theme={bitrixTheme}>
      <Edit
        {...props}
        classes={{ root: classes.root }}
        onSuccess={(data) => {
          window.parent.postMessage(
            { type: 'v1ItemModified', value: { type: 'RealtyObjects', id: data.data.id } },
            '*',
          );
        }}
      >
        <FormObjectsB24
          {...props}
          redirect={false}
          authId={currentProfile?.id}
          syncWithLocation={false}
        />
      </Edit>
    </ThemeProvider>
  );
};
