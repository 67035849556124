import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormTab } from 'react-admin';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { TextInput } from '../../TextInput/TextInput';
import { NumberInput, BooleanInput, TabbedForm, FormTitle, Map, RichText } from '../../common';

import { validates, checkRoleAdminOrSupervisor } from '../../../utils';
import { SeoTab } from '../../formTabs';
import { FormRow } from '../../FormRow';
import { ConstantSelect, AutocompleteWithFormRow, TasksTab } from '../../app';
import {
  publicationStatuses,
  luxuryClasses,
  groupStatuses,
  notificationTargets,
  villageSchemeLinks,
} from '../../../constants';

// import { ExpertSelect } from '../../RASelects';
import { ListImagesOrVideos, ListInfrastructures } from '../../additionalLists';
import { FormCard } from '../../FormCard';
import { GeoTreeTexts } from '../GeoSeeds';

export const FormVillages = (props) => {
  const { permissions, location, id, record } = props;

  const [geoSeed, setGeoSeed] = useState(record.geoSeed);
  const handleChangeGeoSeed = useCallback((newValue) => {
    setGeoSeed(newValue);
  }, []);

  useEffect(() => {
    // fix for map
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const linkElements = useMemo(() => {
    if (!id || !(id in villageSchemeLinks)) {
      return null;
    }

    return villageSchemeLinks[id].map((link) => {
      return (
        <div>
          <a
            href={link.href}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <PictureAsPdfIcon style={{ marginRight: '8px' }} />
            {link.text}
          </a>
        </div>
      );
    });
  }, [id]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Система">
        <FormCard>
          <FormTitle>Идентификация</FormTitle>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <FormRow>
            <TextInput
              source="friendlyId"
              label="Код комплекса"
              validate={validates.friendlyId}
              disabled={!hasRoleAdminOrSupervisor && record.friendlyId}
            />
          </FormRow>
          {/* <FormTitle>Ответственный эксперт</FormTitle>
        <ExpertSelect {...props} /> */}
          <FormTitle>Публикация</FormTitle>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="groupTypes"
            resourceName="RealtyGroupTypes"
            resourceId="groupTypeId"
            additionalSource="groupType"
            componentProps={{
              label: 'Тип',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <BooleanInput label="Эксклюзив - Villagio" source="isVillagio" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Хит продаж" source="isHit" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Цена по запросу" source="isPriceByCall" />
          </FormRow>
          <FormTitle>Поисковые слова</FormTitle>
          <FormRow maxWidth={800} isLast>
            <TextInput source="searchWords" label="Поисковые слова" multiline rows={6} />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Сайт">
        <FormCard>
          <FormTitle>Описание</FormTitle>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <FormRow maxWidth={800}>
            <TextInput
              source="shortDescription"
              label="Краткое описание"
              multiline
              rows={6}
              validate={validates.shortDescription}
            />
          </FormRow>
          <FormRow maxWidth={800}>
            <TextInput
              source="pdfDescription"
              label="Описание для презентации"
              multiline
              rows={6}
            />
          </FormRow>
          <FormTitle>Порядок на сайте</FormTitle>
          <FormRow isLast>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
              disabled={!hasRoleAdminOrSupervisor}
            />
          </FormRow>
          <FormTitle>Второй Заголовок</FormTitle>
          <FormRow>
            <RichText source="title2" />
          </FormRow>
          <FormTitle>Кнопка скачать презентацию</FormTitle>
          <FormRow>
            <TextInput source="presaButton" label="Текст кнопки" />
          </FormRow>
          <FormRow>
            <TextInput source="presaLink" label="Ссылка кнопки" />
          </FormRow>
          <FormTitle>Кнопка узнать цену</FormTitle>
          <FormRow>
            <TextInput source="showPriceButton" label="Текст кнопки" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Локация">
        <FormCard>
          <AutocompleteWithFormRow
            {...props}
            source="geoSeed"
            resourceName="GeoSeeds"
            resourceId="geoSeedId"
            onChange={handleChangeGeoSeed}
            componentProps={{
              label: 'География',
              fullDynamic: 'NameContains',
              // getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
              providerParams: {
                filter: {
                  isGeoSeedParents: true,
                  isGeoSeedChildren: true,
                  FlatDetails: 300,
                  RelDetails: 300,
                },
              },
            }}
          />
          {geoSeed && (
            <div style={{ marginBottom: '24px', marginTop: '-22px' }}>
              <GeoTreeTexts
                current={geoSeed}
                parents={geoSeed.parentGeoSeeds}
                childrens={geoSeed.childGeoSeeds}
              />
            </div>
          )}
          <AutocompleteWithFormRow
            {...props}
            source="geoTags"
            resourceName="GeoTags"
            resourceId="geoTagId"
            additionalSource="geoTag"
            componentProps={{
              label: 'Локация',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <Map {...props} />
          <FormRow>
            <NumberInput min={0} source="distance" label="Расстояние до МКАД" />
          </FormRow>
          <FormRow maxWidth={800}>
            <TextInput source="address" label="Адрес для фидов" />
          </FormRow>
          <FormRow>
            <TextInput source="addressPostalIndex" label="Индекс" />
          </FormRow>
          <FormRow>
            <TextInput source="addressCountry" label="Страна" />
          </FormRow>
          <FormRow>
            <TextInput source="addressRegion" label="Регион/Область" />
          </FormRow>
          <FormRow>
            <TextInput source="addressLocality" label="Город" />
          </FormRow>
          <FormRow>
            <TextInput source="addressStreet" label="Улица" />
          </FormRow>
          <FormRow>
            <TextInput source="addressPropertyNo" label="Дом" />
          </FormRow>
          <FormRow>
            <TextInput source="addressBlock" label="Корпус" />
          </FormRow>
          <FormRow isLast>
            <TextInput source="addressBuilding" label="Строение" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="О комплексе">
        <FormCard>
          <FormTitle>Общее</FormTitle>
          <FormRow>
            <TextInput
              source="area"
              label="Площадь"
              InputProps={{
                endAdornment: <span>сот.</span>,
              }}
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="developers"
            resourceName="Developers"
            resourceId="developerId"
            additionalSource="developer"
            componentProps={{
              label: 'Застройщики',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <NumberInput min="0" source="year" label="Год постройки" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="realtyGroupStatus"
              objectChoices={groupStatuses}
              label="Статус готовности"
              allowEmpty
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="groupFeatures"
            resourceName="RealtyGroupFeatures"
            resourceId="groupFeatureId"
            additionalSource="groupFeature"
            componentProps={{
              label: 'Преимущества комплекса',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <ConstantSelect
              source="luxuryClass"
              objectChoices={luxuryClasses}
              label="Класс комплекса"
              allowEmpty
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="terrainTags"
            resourceName="TerrainTags"
            resourceId="terrainTagId"
            additionalSource="terrainTag"
            componentProps={{
              label: 'Особенности участка',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <BooleanInput label="Охраняется" source="isSecure" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Вывоз мусора" source="isGarbageRemoval" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Электричество" source="isElectricity" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Центральная канализация" source="isSewerage" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Водопровод" source="isWater" />
          </FormRow>
          <FormRow isLast>
            <BooleanInput label="Газ" source="isGas" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Продвижение">
        <FormCard>
          <FormRow>
            <ConstantSelect
              source="notificationTargets"
              objectChoices={notificationTargets}
              label="Уведомлять об изменениях"
              allowEmpty
              parse={(value) => {
                return value ? [value] : '';
              }}
              format={(value) => {
                return value ? value[0] : '';
              }}
            />
          </FormRow>
          <FormTitle>Seo</FormTitle>
          <SeoTab isLast={false} />
          <FormTitle>Инком</FormTitle>
          <FormRow>
            <TextInput source="highwayAdd" label="Шоссе доп. (HIGHWAYADD)" />
          </FormRow>
          <FormRow>
            <BooleanInput source="isNewMoscow" label="Новая Москва (NEWMOSCOW)" />
          </FormRow>
          <FormRow>
            <TextInput source="termOfDelivery" label="Готовность и сроки сдачи (TERMOFDELIVERY)" />
          </FormRow>
          <FormRow>
            <TextInput source="currencyCode" label="Публиковать цену в валюте (CURRENCYCODE)" />
          </FormRow>
          <FormRow>
            <TextInput source="webSite" label="Промосайт (WEBSITE)" />
          </FormRow>
          <FormRow>
            <BooleanInput source="isFeedPricesPublicRub" label="Публиковать рублёвые цены в фиде" />
          </FormRow>
          <FormRow>
            <BooleanInput
              source="isFeedPricesPublicUsd"
              label="Публиковать долларовые цены в фиде"
            />
          </FormRow>
          <FormRow>
            <TextInput source="infrastructureAdd" label="Инфраструктура доп. (INFRASTRUCTURE)" />
          </FormRow>
          <FormRow>
            <TextInput source="notesAdd" label="Окружающая природа доп. (NOTES)" />
          </FormRow>
          <FormRow>
            <TextInput source="filial" label="Номер для сайта (FILIAL)" />
          </FormRow>
          <FormRow>
            <TextInput source="internetType" label="Интернет (INTERNETTYPE)" />
          </FormRow>
          <FormRow>
            <TextInput source="spec" label="СпецТипцову (SPEC)" />
          </FormRow>
          <FormRow>
            <TextInput source="profitPosition" label="Место по ВП (PROFITPOSITION)" />
          </FormRow>
          <FormRow>
            <TextInput source="servicelineSeo" label="Информация для сайта (SERVICELINE_SEO)" />
          </FormRow>
          <FormRow>
            <TextInput source="mortgage" label="Ипотека (MORTGAGE)" />
          </FormRow>
          <FormRow>
            <TextInput source="action" label="Акция (ACTION)" />
          </FormRow>
          <FormRow>
            <TextInput source="trees" label="Деревья (TREES)" />
          </FormRow>
          <FormRow>
            <TextInput source="villageWalls" label="Ограждение комплекса (VILLAGE_WALS)" />
          </FormRow>
          <FormRow>
            <TextInput source="howToDrive" label="Описание проезда (HOW2DRIVE)" />
          </FormRow>
          <FormRow>
            <TextInput source="landCategory" label="Категория земли (LAND_CATEGORY)" />
          </FormRow>
          <FormTitle>Циан</FormTitle>
          <FormRow>
            <NumberInput source="anyJsonDictionary.KPId" label="ID КП Циана" helperText="KPId" />
          </FormRow>
          <FormRow>
            <NumberInput
              source="anyJsonDictionary.JKSchemaID"
              label="ID ЖК Циана"
              helperText="JKSchemaID"
            />
          </FormRow>
          <FormTitle>Яндекс</FormTitle>
          <FormRow>
            <NumberInput
              source="anyJsonDictionary.yandexBuildingId"
              label="Идентификатор жилого комплекса в базе Яндекса"
              helperText="yandexBuildingId"
            />
          </FormRow>
          <FormRow>
            <NumberInput
              source="anyJsonDictionary.yandexHouseId"
              label="Идентификатор корпуса жилого комплекса в базе Яндекса"
              helperText="yandexHouseId"
            />
          </FormRow>
          {id && (
            <>
              <FormTitle>Статистика</FormTitle>
              <FormRow>
                <NumberInput
                  source="stats.priceMin"
                  label="Минимальная цена из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.priceMax"
                  label="Максимальная цена из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.priceRentMin"
                  label="Минимальная цена аренды из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.priceRentMax"
                  label="Максимальная цена аренды из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.priceMinUsd"
                  label="Минимальная цена из доступного в Usd"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.priceMaxUsd"
                  label="Максимальная цена из доступного в Usd"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.priceRentMinUsd"
                  label="Минимальная цена аренды из доступного в Usd"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.priceRentMaxUsd"
                  label="Максимальная цена аренды из доступного в Usd"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.plottageMin"
                  label="Минимальный размер участка из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.plottageMax"
                  label="Максимальный размер участка из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.floorAreaMin"
                  label="Минимальный размер дома из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.floorAreaMax"
                  label="Максимальный размер дома из доступного"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.quantity"
                  label="Количество в продаже или аренде доступно"
                  disabled
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="stats.quantitySale"
                  label="Количество в продаже доступно"
                  disabled
                />
              </FormRow>
              <FormRow isLast>
                <NumberInput
                  source="stats.quantityRent"
                  label="Количество в аренде доступно"
                  disabled
                />
              </FormRow>
            </>
          )}
        </FormCard>
      </FormTab>
      <FormTab label="Изображения">
        <ListImagesOrVideos id={id} hasMultiple hasTemplateName />
      </FormTab>
      <FormTab label="Видео">
        <ListImagesOrVideos id={id} isVideo />
      </FormTab>
      <FormTab label="Инфраструктура">
        <ListInfrastructures />
      </FormTab>
      {/* <FormTab label="Объекты">
        <ListObjects />
      </FormTab> */}
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={100} location={location} />
        </FormTab>
      )}
      {linkElements && (
        <FormTab label="Ссылки на схемы">
          <FormCard>
            <FormTitle>Ссылки на схемы</FormTitle>
            <FormRow>{linkElements}</FormRow>
          </FormCard>
        </FormTab>
      )}
    </TabbedForm>
  );
};
