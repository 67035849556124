import { AutocompleteDynamic, TextField } from '../components/common';
import { ConstantSelectField } from '../components/app/ConstantSelectField';
import { FilterGroup } from '../components/app/FilterGroup';
import { FilterFromTo } from '../components/app/FilterFromTo';
import {
  objectContractTypes,
  currencyTypes,
  booleans,
  filtersPublicationStatuses,
  changeHistoryTypes,
  // sortOrders,
  // objectSortOrders,
  expoTypes,
  changeHistoryActions,
  clientTouchTypes,
  ownerStatuses,
  touchesTypes,
  contractTypes,
  contractStatuses,
  showResults,
  luxuryClasses,
  initialSales,
  // clientSortOrders,
  visitShowStatuses,
  clientProcessStatuses,
  showCalcStatuses,
} from '.';

import { getFullName, getFullNameWithId, isObject } from '../utils';
import { taskObjectTypes } from './tasks';
import { DateTimePickerInput } from '../components/RAInputs';
import { getFunnelSteps } from './yucrm';

const idValueModifier = (value) => {
  if (!value) {
    return '';
  }

  if (!('id' in value)) {
    alert('Error, wrong modifier');

    return '';
  }

  return value.id;
};

const getPeopleLabel = (user) => {
  return `${getFullNameWithId(user)}`;
};

export const commonFilters = [
  {
    id: 100,
    label: 'Статус публикации',
    filter: 'Status',
    component: ConstantSelectField,
    componentProps: {
      allowEmpty: true,
      items: filtersPublicationStatuses,
    },
  },
  // {
  //   id: 101,
  //   label: 'Сортировка',
  //   filter: 'SortBy',
  //   component: ConstantSelectField,
  //   componentProps: {
  //     allowEmpty: true,
  //     items: sortOrders,
  //   },
  // },
];

export const filtersByResources = {
  RealtyFeeds: [
    {
      id: 1,
      label: 'Только системные?',
      filter: 'IsSystem',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
  ],
  Clients: [
    // {
    //   id: 23,
    //   label: 'Сортировка',
    //   filter: 'SortBy',
    //   component: ConstantSelectField,
    //   componentProps: {
    //     allowEmpty: true,
    //     items: clientSortOrders,
    //   },
    // },
    {
      id: 1,
      label: 'Статус обработки',
      filter: 'ProcessStatuses',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: clientProcessStatuses,
      },
    },
    {
      id: 2,
      label: 'Номер телефона',
      filter: 'NormalizedPhonesContains',
      component: TextField,
    },
    {
      id: 3,
      label: 'Теги',
      filter: 'ClientTags',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'ClientTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 4,
      label: 'Не доступен?',
      filter: 'IsClosed',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 5,
      label: 'Статус клиента',
      filter: 'Targets',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: clientTouchTypes,
      },
    },
    {
      id: 6,
      label: 'Собственник?',
      filter: 'IsOwner',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 7,
      label: 'Представитель?',
      filter: 'IsRepresentative',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 8,
      label: 'Есть отложенный звонок?',
      filter: 'IsDeferredTouch',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 9,
      label: 'Класс объекта',
      filter: 'LuxuryClasses',
      component: ConstantSelectField,
      componentProps: {
        items: luxuryClasses,
        multiple: true,
      },
    },
    {
      id: 10,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата создания',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['MinCreatedDate', 'MaxCreatedDate'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 2,
            label: 'Дата изменения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['MinChangedDate', 'MaxChangedDate'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 3,
            label: 'Дата последнего касания',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['LastTouchDateMin', 'LastTouchDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 21,
      label: 'Есть лиды в статусах',
      filter: 'HaveContractsInStatus',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: contractStatuses,
      },
    },
    {
      id: 22,
      label: 'Целевые?',
      filter: 'IsTargetedBool',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },

    {
      id: 24,
      label: 'Есть касания?',
      filter: 'HasTouches',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 11,
      label: 'UTM Medium первого касания',
      filter: 'UtmMediumFirstContains',
      component: TextField,
    },
    {
      id: 12,
      label: 'UTM Source первого касания',
      filter: 'UtmSourceFirstContains',
      component: TextField,
    },
    {
      id: 13,
      label: 'UTM Campaign первого касания',
      filter: 'UtmCampaignFirstContains',
      component: TextField,
    },
    {
      id: 14,
      label: 'UTM Content первого касания',
      filter: 'UtmContentFirstContains',
      component: TextField,
    },
    {
      id: 15,
      label: 'Site Page первого касания',
      filter: 'SitePageFirstContains',
      component: TextField,
    },
    {
      id: 16,
      label: 'UTM Medium последнего касания',
      filter: 'UtmMediumLastContains',
      component: TextField,
    },
    {
      id: 17,
      label: 'UTM Source последнего касания',
      filter: 'UtmSourceLastContains',
      component: TextField,
    },
    {
      id: 18,
      label: 'UTM Campaign последнего касания',
      filter: 'UtmCampaignLastContains',
      component: TextField,
    },
    {
      id: 19,
      label: 'UTM Content последнего касания',
      filter: 'UtmContentLastContains',
      component: TextField,
    },
    {
      id: 20,
      label: 'Site Page последнего касания',
      filter: 'SitePageLastContains',
      component: TextField,
    },
  ],
  ClientTouches: [
    {
      id: 1,
      label: 'Теги',
      filter: 'ClientTags',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'ClientTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 2,
      label: 'Статус обработки',
      filter: 'Targets',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: clientTouchTypes,
      },
    },
    {
      id: 3,
      label: 'Тип касания',
      filter: 'TechSource',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: touchesTypes,
      },
    },
    {
      id: 4,
      label: 'Входящие?',
      filter: 'IsInDirection',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 5,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата создания',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['CreatedDateMin', 'CreatedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 2,
            label: 'Дата начала звонка',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['StartedDateMin', 'StartedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 6,
      label: 'Клиент',
      filter: 'ClientId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsClient: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 7,
      label: 'Сотрудник',
      filter: 'EmployeeId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsStaff: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 8,
      label: 'Пропущенный?',
      filter: 'IsMissed',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 9,
      label: 'Номер телефона',
      filter: 'NormalizedPhonesContains',
      component: TextField,
    },
    {
      id: 10,
      label: 'Класс объекта',
      filter: 'LuxuryClasses',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: luxuryClasses,
      },
    },
    {
      id: 16,
      label: 'Целевые?',
      filter: 'IsTargetedBool',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 11,
      label: 'UTM Medium',
      filter: 'UtmMediumContains',
      component: TextField,
    },
    {
      id: 12,
      label: 'UTM Source',
      filter: 'UtmSourceContains',
      component: TextField,
    },
    {
      id: 13,
      label: 'UTM Campaign',
      filter: 'UtmCampaignContains',
      component: TextField,
    },
    {
      id: 14,
      label: 'UTM Content',
      filter: 'UtmContentContains',
      component: TextField,
    },
    {
      id: 15,
      label: 'Site Page',
      filter: 'SitePageContains',
      component: TextField,
    },
  ],
  Contracts: [
    {
      id: 1,
      label: 'Сценарий лида',
      filter: 'Type',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: contractTypes,
      },
    },
    {
      id: 2,
      label: 'Этап воронки',
      filter: 'ContractStatus',
      component: ConstantSelectField,
      componentProps: {
        items: contractStatuses,
        multiple: true,
      },
    },
    {
      id: 3,
      label: 'Создатель',
      filter: 'CreatedId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsStaff: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 4,
      label: 'Эксперт принял лид',
      filter: 'FirstExpertId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsExpert: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 5,
      label: 'Текущий эксперт',
      filter: 'CurrentExpertId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsExpert: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 6,
      label: 'Клиент',
      filter: 'MainClientId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsClient: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 7,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата создания',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['CreatedDateMin', 'CreatedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 2,
            label: 'Дата изменения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['LastModifiedMin', 'LastModifiedMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 3,
            label: 'Дата подписания ДКП',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['DkpDateMin', 'DkpDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 4,
            label: 'Дата закрытия',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['ClosedDateMin', 'ClosedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          // {
          //   id: 4,
          //   label: 'Дата поступления ДВР',
          //   component: FilterFromTo,
          //   isGroup: true,
          //   componentProps: {
          //     filter: ['DvrDateMin', 'DvrDateMax'],
          //     type: 'datetime-local',
          //     inline: false,
          //   },
          // },
        ],
      },
    },
    {
      id: 8,
      label: 'Класс объекта',
      filter: 'LuxuryClasses',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: luxuryClasses,
      },
    },
    {
      id: 10,
      label: 'Тип недвижимости',
      filter: 'RealtyObjectTypes',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjectTypes',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 11,
      label: 'Комплексы',
      filter: 'RealtyGroups',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyGroups',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 12,
      label: 'Объекты',
      filter: 'RealtyObjects',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjects',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'QueryWithFid',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 13,
      label: 'Бюджет',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['BudgetFrom', 'BudgetTo'],
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 14,
      label: 'Площадь дома',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['FloorAreaFrom', 'FloorAreaTo'],
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 15,
      label: 'Площадь участка',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['PlottageFrom', 'PlottageTo'],
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 16,
      label: 'Вилладжио?',
      filter: 'IsVillagio',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 17,
      label: 'Есть показы?',
      filter: 'HasDemos',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 18,
      label: 'Есть просроченные задачи',
      filter: 'IsOutdatedTaasks',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 34,
      label: 'Пригласил на показ?',
      filter: 'IsInvited',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 35,
      label: 'Тэги клиентов',
      filter: 'ClientTags',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'ClientTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 36,
      label: 'Теги лидов',
      filter: 'ContractTags',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'ContractTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 37,
      label: 'Первичка или вторичка?',
      filter: 'IsInitialSale',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: initialSales,
      },
    },
    {
      id: 38,
      label: 'Касание',
      filter: 'ClientTouchId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'ClientTouches',
        fullDynamic: 'IdPhoneContains',
        needLoadDefaultValue: true,
        getOptionLabel: (option) => {
          let label = `${option.id}`;
          if (option.client) {
            label += ` | ${getFullName(option.client)}`;
          }

          if (option.externalPhoneNumber) {
            label += ` | ${option.externalPhoneNumber}`;
          }

          return label;
        },
      },
      valueModifier: idValueModifier,
    },
    {
      id: 39,
      label: 'Нет активных задач?',
      filter: 'IsNoTaasks',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 40,
      label: 'Нет тэгов клиента',
      filter: 'ClientTagsNot',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'ClientTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 41,
      label: 'Нет тэгов лида',
      filter: 'ContractTagsNot',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'ContractTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 42,
      label: 'Обзвоны баз',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Обзвон был не позднее',
            filter: 'ObLastBefore',
            component: DateTimePickerInput,
            componentProps: {
              type: 'datetime-local',
              inline: false,
              isRA: false,
              helperText:
                'Если указана, то фильтровать по завершению последней задачи на обзвон базы',
            },
          },
          {
            id: 2,
            label: 'Есть обзвоны в плане?',
            filter: 'ObUseIncompleted',
            component: ConstantSelectField,
            componentProps: {
              allowEmpty: true,
              items: booleans,
              helperText:
                'Если "Да" и задана "Обзвон был не позднее", то включать незавершённые задачи, иначе игнорится',
            },
          },
          {
            id: 3,
            label: 'Есть/были задачи на обзвон баз?',
            filter: 'ObAny',
            component: ConstantSelectField,
            componentProps: {
              allowEmpty: true,
              items: booleans,
              helperText:
                'Да - Показывать только тех, у кого была хотя бы одна задача на обзвон базы в любом статусе. Нет - то же, но нет ни одной',
            },
          },
        ],
      },
    },
    {
      id: 43,
      label: 'Направление',
      filter: 'ClientSalesDirectionIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'SalesDirections',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 44,
      label: 'Направление эксперта',
      filter: 'ExpertSalesDirectionIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'SalesDirections',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 19,
      label: 'UTM Medium первого касания',
      filter: 'UtmMediumFirstContains',
      component: TextField,
    },
    {
      id: 20,
      label: 'UTM Source первого касания',
      filter: 'UtmSourceFirstContains',
      component: TextField,
    },
    {
      id: 21,
      label: 'UTM Campaign первого касания',
      filter: 'UtmCampaignFirstContains',
      component: TextField,
    },
    {
      id: 22,
      label: 'UTM Content первого касания',
      filter: 'UtmContentFirstContains',
      component: TextField,
    },
    {
      id: 23,
      label: 'Site Page первого касания',
      filter: 'SitePageFirstContains',
      component: TextField,
    },
    {
      id: 24,
      label: 'UTM Medium последнего касания',
      filter: 'UtmMediumLastContains',
      component: TextField,
    },
    {
      id: 25,
      label: 'UTM Source последнего касания',
      filter: 'UtmSourceLastContains',
      component: TextField,
    },
    {
      id: 26,
      label: 'UTM Campaign последнего касания',
      filter: 'UtmCampaignLastContains',
      component: TextField,
    },
    {
      id: 27,
      label: 'UTM Content последнего касания',
      filter: 'UtmContentLastContains',
      component: TextField,
    },
    {
      id: 28,
      label: 'Site Page последнего касания',
      filter: 'SitePageLastContains',
      component: TextField,
    },
    {
      id: 29,
      label: 'Utm-medium собственного касания',
      filter: 'UtmMediumSelfContains',
      component: TextField,
    },
    {
      id: 30,
      label: 'UTM Source собственного касания',
      filter: 'UtmSourceSelfContains',
      component: TextField,
    },
    {
      id: 31,
      label: 'UTM Campaign собственного касания',
      filter: 'UtmCampaignSelfContains',
      component: TextField,
    },
    {
      id: 32,
      label: 'UTM Content собственного касания',
      filter: 'UtmContentSelfContains',
      component: TextField,
    },
    {
      id: 33,
      label: 'Site Page собственного касания',
      filter: 'SitePageSelfContains',
      component: TextField,
    },
  ],
  Demos: [
    {
      id: 1,
      label: 'Лид',
      filter: 'ContractId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Contracts',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 2,
      label: 'Статус показа',
      filter: 'DemoCalcStatus',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: showCalcStatuses,
      },
    },
    {
      id: 3,
      label: 'Создатель',
      filter: 'CreatedId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsStaff: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 4,
      label: 'Эксперт',
      filter: 'ExpertId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsExpert: true,
          },
        },
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 7,
      label: 'Результат показа',
      filter: 'Result',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: showResults,
      },
    },
    {
      id: 8,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата показа (план)',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['DemoDateMin', 'DemoDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 2,
            label: 'Дата показа',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['ActualDateMin', 'ActualDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 3,
            label: 'Дата создания',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['CreatedDateMin', 'CreatedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 4,
            label: 'Дата изменения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['LastModifiedMin', 'LastModifiedMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 9,
      label: 'Обзорная экскурсия',
      filter: 'RealtyGroups',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyGroups',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 10,
      label: 'Объекты',
      filter: 'RealtyObjects',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjects',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'QueryWithFid',
      },
      valueModifier: idValueModifier,
    },
    // {
    //   id: 11,
    //   label: 'Важные объекты',
    //   filter: 'RealtyObjects',
    //   component: AutocompleteDynamic,
    //   componentProps: {
    //     multiple: true,
    //     filterSelectedOptions: true,
    //     resource: 'RealtyObjects',
    //     limitTags: 1,
    //     needLoadDefaultValue: true,
    //     fullDynamic: 'QueryWithFid',
    //     providerParams: { filter: { IsImportant: true } },
    //   },
    //   valueModifier: idValueModifier,
    // },
    {
      id: 12,
      label: 'Статус визита показа',
      filter: 'IsNotFirst',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: visitShowStatuses,
      },
    },
  ],
  RealtySectors: [
    // {
    //   id: 1,
    //   label: 'Эксперт',
    //   filter: 'Manager',
    //   component: AutocompleteDynamic,
    //   componentProps: {
    //     multiple: true,
    //     filterSelectedOptions: true,
    //     resource: 'Peoples',
    //     limitTags: 1,
    //     needLoadDefaultValue: true,
    //     getOptionLabel: (option) =>
    //       !isObject(option)
    //         ? ''
    //         : getPeopleLabel(option),
    //     providerParams: { filter: { isExpert: true } },
    //   },
    //   valueModifier: idValueModifier,
    // },
    {
      id: 2,
      label: 'Комплекс',
      filter: 'RealtyGroup',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyGroups',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
  ],
  Taasks: [
    {
      id: 1,
      label: 'Выполненная?',
      filter: 'IsCompleted',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 2,
      label: 'Кем назначено или кому назначено',
      filter: 'AssignedBoth',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsStaff: true,
          },
        },
        limitTags: 1,
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 3,
      label: 'Кем назначено',
      filter: 'AssignedBy',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsStaff: true,
          },
        },
        limitTags: 1,
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 4,
      label: 'Кому назначено',
      filter: 'AssignedTo',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        providerParams: {
          filter: {
            IsStaff: true,
          },
        },
        limitTags: 1,
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
      },
      valueModifier: idValueModifier,
    },
    {
      id: 5,
      label: 'Просроченная?',
      filter: 'IsCalcOutdated',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 6,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата создания',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['CreatedDateMin', 'CreatedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 2,
            label: 'Планируемая дата выполнения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['PlannedDateMin', 'PlannedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 3,
            label: 'Дата выполнения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['CompletedDateMin', 'CompletedDateMax'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 7,
      label: 'Тип объекта',
      filter: 'TaaskObjectTypes',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: taskObjectTypes,
      },
    },
    {
      id: 8,
      label: 'Тип задачи',
      filter: 'TaaskTypeIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'TaaskTypes',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
  ],
  ChangeHistory: [
    {
      id: 1,
      label: 'По типу и id',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Тип объекта',
            filter: 'ObjectType',
            component: ConstantSelectField,
            componentProps: {
              allowEmpty: true,
              items: changeHistoryTypes,
            },
          },
          {
            id: 2,
            label: 'Id объекта',
            filter: 'ObjectId',
            component: TextField,
            componentProps: {
              type: 'number',
            },
          },
        ],
      },
    },
    {
      id: 2,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата изменения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['ChangeDateFrom', 'ChangeDateTo'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 3,
      label: 'Кем изменено',
      filter: 'ChangedBy',
      component: TextField,
    },
    {
      id: 4,
      label: 'Действие',
      filter: 'Action',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: changeHistoryActions,
      },
    },
  ],
  TaaskHistory: [
    {
      id: 1,
      label: 'ID задачи',
      filter: 'TaaskId',
      component: TextField,
      componentProps: {
        type: 'number',
      },
    },
    {
      id: 2,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата изменения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['ChangeDateFrom', 'ChangeDateTo'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 3,
      label: 'Кем изменено',
      filter: 'ChangedBy',
      component: TextField,
    },
  ],
  ContractHistory: [
    {
      id: 1,
      label: 'ID лида',
      filter: 'ContractId',
      component: TextField,
      componentProps: {
        type: 'number',
      },
    },
    {
      id: 2,
      label: 'ID показа',
      filter: 'DemoId',
      component: TextField,
      componentProps: {
        type: 'number',
      },
    },
    {
      id: 3,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата изменения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['ChangeDateFrom', 'ChangeDateTo'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 4,
      label: 'Кем изменено',
      filter: 'ChangedBy',
      component: TextField,
    },
  ],
  ClientsHistory: [
    {
      id: 1,
      label: 'ID клиента',
      filter: 'ClientId',
      component: TextField,
      componentProps: {
        type: 'number',
      },
    },
    {
      id: 2,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата изменения',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['ChangeDateFrom', 'ChangeDateTo'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 3,
      label: 'Кем изменено',
      filter: 'ChangedBy',
      component: TextField,
    },
  ],
  RealtyObjectSets: [
    {
      id: 1,
      label: 'Эксперт',
      filter: 'ManagerId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        limitTags: 1,
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
        providerParams: { filter: { isExpert: true } },
      },
      valueModifier: idValueModifier,
    },
    {
      id: 2,
      label: 'Тип',
      filter: 'ObjectSetType',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'realtyObjectSetTypes',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
  ],
  RealtyObjectDiscounts: [
    {
      id: 1,
      label: 'Да - Акции, Нет - Скидки, Пусто - все вместе.',
      filter: 'IsAction',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 2,
      label: 'Действует на продажу?',
      filter: 'ForSale',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 3,
      label: 'Действует на аренду?',
      filter: 'ForRent',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 4,
      label: 'В процентах?',
      filter: 'IsPercents',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 5,
      label: 'Скидка',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['AmountFrom', 'AmountTo'],
            },
          },
        ],
      },
    },
    {
      id: 6,
      label: 'Валюта',
      filter: 'CurrencyType',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: currencyTypes,
      },
    },
    {
      id: 7,
      label: 'Даты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Дата начала действия скидки',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['StartFrom', 'StartTo'],
              type: 'datetime-local',
              inline: false,
            },
          },
          {
            id: 2,
            label: 'Дата окончания действия скидки',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['EndFrom', 'EndTo'],
              type: 'datetime-local',
              inline: false,
            },
          },
        ],
      },
    },
  ],
  Peoples: [
    {
      id: 1,
      label: 'Эксперт?',
      filter: 'IsExpert',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 2,
      label: 'Менеджер?',
      filter: 'IsManager',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 3,
      label: 'Супервизор?',
      filter: 'IsSupervisor',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 4,
      label: 'Администратор?',
      filter: 'IsAdmin',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 5,
      label: 'Клиент?',
      filter: 'IsClient',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 6,
      label: 'Квартал',
      filter: 'OwnerInRealtySectorIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtySectors',
        needLoadDefaultValue: true,
        limitTags: 1,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 7,
      label: 'Комплекс',
      filter: 'OwnerInRealtyGroupIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyGroups',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 8,
      label: 'Собственник?',
      filter: 'IsOwner',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 9,
      label: 'Представитель?',
      filter: 'IsRepresentative',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 10,
      label: 'ОДП?',
      filter: 'IsOdp',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 11,
      label: 'Сотрудник villagio?',
      filter: 'IsStaff',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 12,
      label: 'Статус собственника',
      filter: 'OwnerStatuses',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: ownerStatuses,
      },
    },
    {
      id: 13,
      label: 'День рождения',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['BirthDateMin', 'BirthDateMax'],
              type: 'date',
              inline: false,
            },
          },
        ],
      },
    },
    {
      id: 14,
      label: 'Житель?',
      filter: 'IsResident',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 15,
      label: 'Гость?',
      filter: 'IsGuest',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 16,
      label: 'Подписчик?',
      filter: 'IsSubscribed',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 17,
      label: 'Сервисный эксперт?',
      filter: 'IsServiceManager',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 18,
      label: 'Направление',
      filter: 'SalesDirectionIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'SalesDirections',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
  ],
  RealtyGroups: [
    {
      id: 1,
      label: 'Наши комплексы?',
      filter: 'IsVillagio',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 2,
      label: 'География',
      filter: 'GeoSeedId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'GeoSeeds',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 3,
      label: 'Тип',
      filter: 'GroupType',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyGroupTypes',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 4,
      label: 'Только из YUcrm?',
      filter: 'IsYuOnly',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
  ],
  GeoSeeds: [
    {
      id: 1,
      label: 'Родитель',
      filter: 'ParentId',
      component: AutocompleteDynamic,
      componentProps: {
        filterSelectedOptions: true,
        resource: 'GeoSeeds',
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
  ],
  RealtyObjects: [
    // {
    //   id: 21,
    //   label: 'Сортировка',
    //   filter: 'SortBy',
    //   component: ConstantSelectField,
    //   componentProps: {
    //     allowEmpty: true,
    //     items: objectSortOrders,
    //   },
    // },
    {
      id: 1,
      label: 'Локация',
      filter: 'GeoTagRoOrRg',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'GeoTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 2,
      label: 'Комплекс | ЖК',
      filter: 'RealtyGroup',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyGroups',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 3,
      label: 'Тип',
      filter: 'Type',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjectTypes',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 4,
      label: 'Состояние',
      filter: 'CurrentState',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjectStates',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 5,
      label: 'Цена',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Валюта',
            filter: 'Currency',
            component: ConstantSelectField,
            componentProps: {
              allowEmpty: true,
              items: currencyTypes,
            },
          },
          {
            id: 2,
            label: 'Цена продажи',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['MinPriceSale', 'MaxPriceSale'],
            },
          },
          {
            id: 3,
            label: 'Цена аренды',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['MinPriceRent', 'MaxPriceRent'],
            },
          },
        ],
      },
    },
    {
      id: 6,
      label: 'Площадь',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Площадь дома, m2',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['FloorAreaMin', 'FloorAreaMax'],
            },
          },
          {
            id: 2,
            label: 'Жилая площадь дома, m2',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['UsefulFloorAreaMin', 'UsefulFloorAreaMax'],
            },
          },
          {
            id: 3,
            label: 'Площадь участка, сот',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['PlottageMin', 'PlottageMax'],
            },
          },
        ],
      },
    },
    {
      id: 7,
      label: 'Особенности объекта',
      filter: 'Feature',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjectFeatures',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 8,
      label: 'Тип местности',
      filter: 'TerrainTag',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'TerrainTags',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 9,
      label: 'Статус',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Доступен для аренды',
            filter: 'IsForRent',
            component: ConstantSelectField,
            componentProps: {
              allowEmpty: true,
              items: booleans,
            },
          },
          {
            id: 2,
            label: 'Доступен для продажи',
            filter: 'IsForSale',
            component: ConstantSelectField,
            componentProps: {
              allowEmpty: true,
              items: booleans,
            },
          },
        ],
      },
    },
    {
      id: 10,
      label: 'Эксперт',
      filter: 'ManagerId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        limitTags: 1,
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
        providerParams: { filter: { isExpert: true } },
      },
      valueModifier: idValueModifier,
    },
    {
      id: 11,
      label: 'Коммуникации',
      filter: 'Communication',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjectCommunications',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 12,
      label: 'Архитектурный стиль',
      filter: 'Style',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjectStyles',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 13,
      label: 'Материал',
      filter: 'Material',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtyObjectMaterials',
        limitTags: 1,
        needLoadDefaultValue: true,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 14,
      label: 'Тип договора',
      filter: 'ContractType',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: objectContractTypes,
      },
    },
    {
      id: 15,
      label: 'Спальни',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Количество спален',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['BedroomsMin', 'BedroomsMax'],
            },
          },
          {
            id: 2,
            label: 'Количество мастер-спален',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['MasterBedsMin', 'MasterBedsMax'],
            },
          },
        ],
      },
    },
    {
      id: 16,
      label: 'Ванные и туалеты',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Количества ванных',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['BathroomsMin', 'BathroomsMax'],
            },
          },
          {
            id: 2,
            label: 'Количество ванных в спальнях',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['BathInBedsMin', 'BathInBedsMax'],
            },
          },
          {
            id: 3,
            label: 'Количества туалетов',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['LavatoriesMin', 'LavatoriesMax'],
            },
          },
        ],
      },
    },
    {
      id: 17,
      label: 'Гардеробные',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Количество гардеробных',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['WardrobesMin', 'WardrobesMax'],
            },
          },
          {
            id: 2,
            label: 'Количество гардеробных в спальнях',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['WardrobeInBedsMin', 'WardrobeInBedsMax'],
            },
          },
        ],
      },
    },
    {
      id: 18,
      label: 'Этажи',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Количество этажей',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['FloorsMin', 'FloorsMax'],
            },
          },
        ],
      },
    },
    {
      id: 19,
      label: 'МКАД',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Расстояние до МКАД',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['DistanceToMkadMin', 'DistanceToMkadMax'],
            },
          },
        ],
      },
    },
    {
      id: 20,
      label: 'Год постройки',
      component: FilterGroup,
      isGroup: true,
      componentProps: {
        items: [
          {
            id: 1,
            label: 'Год постройки',
            component: FilterFromTo,
            isGroup: true,
            componentProps: {
              filter: ['YearMin', 'YearMax'],
            },
          },
        ],
      },
    },

    {
      id: 22,
      label: 'Собственник',
      filter: 'OwnerId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        fullDynamic: 'AllNames',
        limitTags: 1,
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
        providerParams: { filter: { isOwner: true } },
      },
      valueModifier: idValueModifier,
    },
    {
      id: 23,
      label: 'Представитель',
      filter: 'RepresentativeId',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'Peoples',
        limitTags: 1,
        needLoadDefaultValue: true,
        getOptionLabel: (option) => (!isObject(option) ? '' : getPeopleLabel(option)),
        providerParams: { filter: { IsRepresentative: true } },
      },
      valueModifier: idValueModifier,
    },
    {
      id: 24,
      label: 'Сектор',
      filter: 'RealtySector',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'RealtySectors',
        needLoadDefaultValue: true,
        limitTags: 1,
      },
      valueModifier: idValueModifier,
    },
    {
      id: 25,
      label: 'Тип продажи',
      filter: 'ExpoType',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: expoTypes,
      },
    },
    {
      id: 26,
      label: 'Эксклюзивные комплексы?',
      filter: 'IsGroupVillagio',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 27,
      label: 'Не используется в фидах?',
      filter: 'ExcludeFromFeeds',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 28,
      label: 'Класс объекта',
      filter: 'luxuryClass',
      component: ConstantSelectField,
      componentProps: {
        multiple: true,
        items: luxuryClasses,
      },
    },
    {
      id: 29,
      label: 'Первичка или вторичка?',
      filter: 'IsInitialSale',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: initialSales,
      },
    },
    {
      id: 30,
      label: 'Важный?',
      filter: 'isImportant',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 31,
      label: 'Только из YUcrm?',
      filter: 'IsYuOnly',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: booleans,
      },
    },
    {
      id: 32,
      label: 'Направление',
      filter: 'OwnSalesDirectionIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'SalesDirections',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 33,
      label: 'Направление эксперта',
      filter: 'ExpertSalesDirectionIds',
      component: AutocompleteDynamic,
      componentProps: {
        multiple: true,
        filterSelectedOptions: true,
        resource: 'SalesDirections',
        limitTags: 1,
        needLoadDefaultValue: true,
        fullDynamic: 'NameContains',
      },
      valueModifier: idValueModifier,
    },
    {
      id: 34,
      label: 'Этап воронки',
      filter: 'FunnelStepIds',
      component: ConstantSelectField,
      componentProps: {
        allowEmpty: true,
        items: getFunnelSteps(true),
        multiple: true,
      },
    },
  ],
};

export const multipleFilters = new Set();

const getMultipleFilters = () => {
  Object.keys(filtersByResources).forEach((key) => {
    filtersByResources[key].forEach((filter) => {
      if (!filter.isGroup) {
        if (filter.componentProps && filter.componentProps.multiple) {
          multipleFilters.add(filter.filter);
        }
      } else if (filter.componentProps && filter.componentProps.items) {
        filter.componentProps.items.forEach((groupFilter) => {
          if (!groupFilter.isGroup) {
            if (groupFilter.componentProps && groupFilter.componentProps.multiple) {
              multipleFilters.add(filter.filter);
            }
          }
        });
      }
    });
  });
};

getMultipleFilters();
