import React from 'react';

import { FormRow } from '../../FormRow';
import { TextInput } from '../../TextInput/TextInput';
import { validates } from '../../../utils';

export const SeoTab = ({ isLast = true, variant = 'filled' }) => {
  return (
    <>
      <FormRow maxWidth={800}>
        <TextInput
          source="seoTitle"
          label="SEO заголовок"
          validate={validates.seoTitle}
          variant={variant}
        />
      </FormRow>
      <FormRow maxWidth={800} isLast={isLast}>
        <TextInput
          source="seoDescription"
          label="SEO описание"
          multiline
          rows={6}
          validate={validates.seoDescription}
          variant={variant}
        />
      </FormRow>
    </>
  );
};
