import React, { useMemo } from 'react';
import { TextInput as RATextInput, useInput } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { mergeClasses } from '../../utils';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: '0',
    },
    filled: {
      '& > div:not(.Mui-disabled)': {
        backgroundColor: theme.palette.primaryNew.light,
      },
      '& > div:hover:not(.Mui-disabled):not(.Mui-error)': {
        backgroundColor: theme.palette.primaryNew.light100,
      },
    },
  };
});

export const TextInput = (props) => {
  const { multiline, className, InputProps, source } = props;
  const classes = useStyles();

  const {
    input: { value },
  } = useInput({
    source,
  });

  const isFilled = useMemo(() => {
    return Boolean(value);
  }, [value]);

  return (
    <RATextInput
      helperText=""
      fullWidth
      variant="filled"
      {...props}
      className={mergeClasses(classes.root, isFilled && classes.filled, className)}
      InputProps={{ ...InputProps, classes: { input: mergeClasses(!multiline && classes.input) } }}
      margin="none"
    />
  );
};
