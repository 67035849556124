// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unreachable */
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormWithRedirect, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { ExpertSelect, OwnerSelect, RepresentativeSelect } from '../../RASelects';
import { dataProvider } from '../../../dataProvider';
import { FormTitle, NumberInput } from '../../common';
import { ConstantSelect, AutocompleteWithFormRow } from '../../app';
import { FormRow } from '../../FormRow';
import {
  avitoListingFee,
  // cianExcludedService,
  // cianService,
  objectContractTypes,
  expoTypes,
  heatingType,
  luxuryClasses,
  publicationStatuses,
  repairType,
  yandexVas,
  booleans,
} from '../../../constants';
import { DateTimePickerInput } from '../../RAInputs';
import { parseBoolean, checkRoleAdminOrSupervisor } from '../../../utils';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ObjectsMultipleDialog = (props) => {
  const { permissions } = props;

  // const hasRoleAdmin = useMemo(() => {
  //   return checkRoleAdmin(permissions);
  // }, [permissions]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const refresh = useRefresh();
  const classes = useStyles();
  const { changeOpenRef, record = {}, resource, data, selectedIds } = props;

  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      const items = selectedIds.map((selectedId) => {
        return data[selectedId];
      });

      const fetch = async () => {
        try {
          await dataProvider.updateMany(resource, items, { ...values });
          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }
          refresh();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, data, refresh, resource, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Редактировать поля объекта"
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormTitle>Основные поля</FormTitle>
              <ExpertSelect />
              <OwnerSelect disabled={!hasRoleAdminOrSupervisor} />
              <RepresentativeSelect />
              <FormRow>
                <ConstantSelect
                  source="isOwnerInside"
                  objectChoices={booleans}
                  label="Проживает собственник"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="sysStatus"
                  objectChoices={publicationStatuses}
                  label="Статус публикации"
                  allowEmpty
                />
              </FormRow>
              {/* <FormRow>
                <ConstantSelect
                  source="isForSale"
                  objectChoices={booleans}
                  label="Для продажи"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="isForRent"
                  objectChoices={booleans}
                  label="Для аренды"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="isPreSold"
                  objectChoices={booleans}
                  label="Сделка завершена"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormDataConsumer>
                {({ formData }) => {
                  if (!formData.isPreSold) {
                    delete formData.soldWith;
                    delete formData.soldPriceRub;
                  }

                  if (formData.isReserved) {
                    formData.isForSale = false;
                    formData.isForRent = false;
                  }

                  if (formData.isPreSold || formData.isSold) {
                    formData.isForSale = false;
                    formData.isForRent = false;
                    formData.sysStatus = 100;
                  }

                  if (formData.lastPreSoldDate) {
                    formData.lastSoldDate = formData.lastPreSoldDate;
                  } else {
                    formData.lastSoldDate = '';
                  }

                  const soldWithUs = Number(formData.soldWith) === 100;

                  if (!soldWithUs) {
                    delete formData.soldPriceRub;
                  }

                  return (
                    <>
                      {formData.isPreSold && (
                        <>
                          <FormRow>
                            <ConstantSelect
                              source="soldWith"
                              objectChoices={soldWith}
                              label="С кем продано"
                              allowEmpty
                              validate={validates.soldWith}
                            />
                          </FormRow>
                          <FormRow>
                            <DateTimePickerInput
                              source="lastPreSoldDate"
                              label="Когда продан"
                              helperText="Когда в последний раз продан по мнению экспертов"
                              validate={validates.lastPreSoldDate}
                            />
                          </FormRow>
                        </>
                      )}
                      {soldWithUs && (
                        <FormRow>
                          <NumberInput
                            source="soldPriceRub"
                            label="Цена за которую продан"
                            helperText="Вводить в рублях!"
                            min={0}
                            validate={validates.soldPriceRub}
                          />
                        </FormRow>
                      )}
                      {hasRoleAdmin && (
                        <>
                          <FormRow>
                            <ConstantSelect
                              source="isSold"
                              objectChoices={booleans}
                              label="Завершение сделки подтверждено"
                              allowEmpty
                              parse={parseBoolean}
                            />
                          </FormRow> */}
              {/* {formData.isSold && (
                            <FormRow>
                              <DateTimeInput
                                source="lastSoldDate"
                                label="Когда продан"
                                helperText="Когда в последний раз продан по мнению администрации"
                                validate={validates.lastSoldDate}
                              />
                            </FormRow>
                          )} */}
              {/* </>
                      )}
                    </>
                  );
                }}
              </FormDataConsumer> */}
              {/* <FormRow>
                <ConstantSelect
                  source="isReserved"
                  objectChoices={booleans}
                  label="Бронь / под авансом"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow> */}
              <FormRow>
                <ConstantSelect
                  source="contractType"
                  objectChoices={objectContractTypes}
                  label="Тип договора"
                />
              </FormRow>
              <FormRow>
                <ConstantSelect source="expoType" objectChoices={expoTypes} label="Тип продажи" />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="isWowOffer"
                  objectChoices={booleans}
                  label="Спецпредложение"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="isHideAnonCoords"
                  objectChoices={booleans}
                  label="Скрыть координаты"
                  allowEmpty
                  parse={parseBoolean}
                  helperText="Отображать координаты комплекса"
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="luxuryClass"
                  objectChoices={luxuryClasses}
                  label="Класс объекта"
                />
              </FormRow>
              <AutocompleteWithFormRow
                source="currentObjectState"
                resourceName="RealtyObjectStates"
                resourceId="currentObjectStateId"
                componentProps={{
                  label: 'Состояние',
                }}
              />
              <FormRow>
                <ConstantSelect
                  source="isUnique"
                  objectChoices={booleans}
                  label="Уникальный объект"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormRow>
                <NumberInput min="0" source="year" label="Год постройки" />
              </FormRow>
              <AutocompleteWithFormRow
                source="realtyObjectStyle"
                resourceName="RealtyObjectStyles"
                resourceId="realtyObjectStyleId"
                componentProps={{
                  label: 'Архитектурный стиль',
                }}
              />
              <AutocompleteWithFormRow
                source="objectFeatures"
                resourceName="RealtyObjectFeatures"
                resourceId="objectFeatureId"
                additionalSource="objectFeature"
                componentProps={{
                  label: 'Преимущества',
                  multiple: true,
                  filterSelectedOptions: true,
                }}
              />
              <AutocompleteWithFormRow
                source="terrainTags"
                resourceName="TerrainTags"
                resourceId="terrainTagId"
                additionalSource="terrainTag"
                componentProps={{
                  label: 'Особенности участка',
                  multiple: true,
                  filterSelectedOptions: true,
                }}
              />
              <FormRow>
                <ConstantSelect
                  source="excludeFromFeeds"
                  objectChoices={booleans}
                  label="Не использовать в фидах"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormTitle>Циан</FormTitle>
              <FormRow>
                <ConstantSelect source="repairType" objectChoices={repairType} label="RepairType" />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="heatingType"
                  objectChoices={heatingType}
                  label="HeatingType"
                />
              </FormRow>
              {/* <FormRow>
                <ConstantSelect
                  source="cianService"
                  objectChoices={cianService}
                  label="ServicesEnum"
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="cianExcludedService"
                  objectChoices={cianExcludedService}
                  label="ExcludedServicesEnum"
                />
              </FormRow> */}
              <FormRow>
                <NumberInput min={0} source="auctionBet" label="Auction	None Bet" />
              </FormRow>
              <FormRow>
                <NumberInput
                  min={0}
                  source="agentFee"
                  label="Комиссия от другого агента"
                  helperText="AgentFee"
                  defaultValue={0}
                  InputProps={{
                    endAdornment: '%',
                  }}
                />
              </FormRow>
              <FormRow>
                <NumberInput
                  min={0}
                  source="clientFee"
                  label="Комиссия от прямого клиента"
                  helperText="ClientFee"
                  defaultValue={0}
                  InputProps={{
                    endAdornment: '%',
                  }}
                />
              </FormRow>
              <FormTitle>Авито</FormTitle>
              <FormRow>
                <ConstantSelect
                  source="avitoListingFee"
                  objectChoices={avitoListingFee}
                  label="ListingFee"
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="safeDemonstration"
                  objectChoices={booleans}
                  label="SafeDemonstration"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
              <FormTitle>Яндекс</FormTitle>
              <FormRow>
                <ConstantSelect source="yandexVas" objectChoices={yandexVas} label="vas" />
              </FormRow>
              <FormRow>
                <DateTimePickerInput source="yandexVasDate" label="vasDate" />
              </FormRow>
              <FormRow isLast>
                <ConstantSelect
                  source="isPmg"
                  objectChoices={booleans}
                  label="pmg"
                  allowEmpty
                  parse={parseBoolean}
                />
              </FormRow>
            </>
          );
        }}
      />
    </Dialog>
  );
};
