/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { FormTab, FormDataConsumer, useNotify } from 'react-admin';
import { Chip, CircularProgress, Link, Tooltip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import { TextInput } from '../../TextInput/TextInput';
import { NumberInput, BooleanInput, TabbedForm, FormTitle, Map, RichText } from '../../common';

import {
  validates,
  checkRoleAdminOrSupervisor,
  isObject,
  getFormattedDateAndTime,
  // getFormattedDate,
} from '../../../utils';
import { SeoTab } from '../../formTabs';
import { FormRow } from '../../FormRow';
import {
  ConstantSelect,
  AutocompleteWithFormRow,
  CommentsTab,
  TasksTab,
  ObjectPriceChart,
  List,
} from '../../app';
import {
  publicationStatuses,
  currencyTypes,
  objectContractTypes,
  luxuryClasses,
  yandexVas,
  avitoListingFee,
  // cianExcludedService,
  // cianService,
  heatingType,
  repairType,
  expoTypes,
  leaseDeposit,
  liquidityTypes,
  initialSales,
  personTypes,
  windowViewTypes,
  utilityMeters,
  landStatuses,
  vatTypes,
  commercialTypes,
} from '../../../constants';

import { ExpertSelect, OwnerSelect, RepresentativeSelect } from '../../RASelects';
import { DynamicList, ListImagesOrVideos, ListInfrastructures } from '../../additionalLists';
import { DateTimePickerInput } from '../../RAInputs';
import { DiscountDatesField, DiscountValueField, NameField } from '../../RAFields';
import { FormCard } from '../../FormCard';
import { СustomNumericInput } from '../Contracts';
import { ListItem } from '../../app/ListItems';
import { dataProvider } from '../../../dataProvider';
import { UnpublishObjectDialog } from '../../dialogs';
import {
  commercialClasses,
  commercialEntranceTypes,
  commercialMainPurposes,
  commercialPurposes,
  commercialStates,
  getFunnelSteps,
  residentialBathroomTypes,
  residentialBathTypes,
  residentialContractTypes,
  residentialDealTypes,
  residentialOwnershipTypes,
  residentialParkingTypes,
  residentialRoomTypes,
  residentialTransportTypes,
  yuCommercialTypes,
} from '../../../constants/yucrm';
// import { MyYMap } from '../../common/YMap';

const useStyles = makeStyles(() => ({
  discountValue: {
    color: '#757575',
    lineHeight: '1.4',
    fontSize: '11px',
    marginTop: '6px',

    '& *': {
      color: '#757575',
      lineHeight: '1.4',
      fontSize: '11px',
    },
  },
  discountDates: {
    color: '#757575',
    lineHeight: '1.4',
    fontSize: '11px',
    marginTop: '6px',
  },
}));

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  return <NameField id={id} name={name} additionalId={slug} />;
};

const DiscountOption = ({ option }) => {
  const styles = useStyles();
  const hasDates = option.start || option.end;

  return (
    <div>
      <WrapperNameField record={option} />
      <div className={styles.discountValue}>
        <DiscountValueField record={option} />
      </div>
      {hasDates && (
        <div className={styles.discountDates}>
          <DiscountDatesField record={option} />
        </div>
      )}
    </div>
  );
};

const chechIsPlot = (formData) => {
  let isPlot = false;

  if (
    Array.isArray(formData?.objectTypes) &&
    Number(formData.objectTypes[0]?.objectTypeId) === 100
  ) {
    isPlot = true;
  }

  if (
    isObject(formData?.objectTypes) &&
    Number(formData.objectTypes.current[0]?.objectTypeId) === 100
  ) {
    isPlot = true;
  }

  return isPlot;
};

const minOldPriceValue = 1000000;

const statsUseStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0',
  },
  item: {
    marginTop: '16px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.24)',
    paddingTop: '16px',

    '&:first-child': {
      marginTop: '0px',
      paddingTop: '0',
      borderTop: 'transparent',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    marginTop: '10px',
    display: 'flex',
    gap: '12px',
  },
  content_title: {
    fontWeight: 'bold',
    paddingBottom: '8px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.24)',
    marginBottom: '8px',
  },
  content_item: {
    flex: '1 1 auto',
  },
  link: {
    fontSize: '18px',
    textTransform: 'uppercase',
  },
  dates: {
    marginBottom: '16px',
  },
  activeLink: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
  containerButton: {
    marginTop: '12px',
  },
}));

export const MarketingStats = ({ id }) => {
  const styles = statsUseStyles();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState();

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const fetch = async () => {
      setLoading(true);
      try {
        const result = await dataProvider.getMarketingStats(id);
        setStats(result.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    fetch();
  }, [id]);

  const items = useMemo(() => {
    if (!stats || !stats.links) {
      return [];
    }

    return stats.links.map((link) => {
      // const statsInfo = stats?.stats?.stats.find(
      //   (statsItem) => statsItem.platform === link.platform,
      // );

      return (
        <div className={styles.item}>
          <div className={styles.header}>
            <div>
              <Link href={link.link} target="_blank" className={styles.link}>
                {link.platform}: {link.type}
              </Link>
            </div>
            <div>
              <Tooltip title="Дата последнего обновления" arrow>
                <Chip
                  variant="outlined"
                  size="small"
                  label={getFormattedDateAndTime(link.time)}
                  classes={{
                    root: link.state === 'active' ? styles.activeLink : styles.defaultLink,
                  }}
                />
              </Tooltip>
            </div>
          </div>
          {/* {statsInfo && (
            <div className={styles.content}>
              <div className={styles.content_item}>
                <div className={styles.content_title}>Просмотров</div>
                <div className={styles.content_text}>{statsInfo.views}</div>
              </div>
              <div className={styles.content_item}>
                <div className={styles.content_title}>Откликов</div>
                <div className={styles.content_text}>{statsInfo.contacts}</div>
              </div>
              <div className={styles.content_item}>
                <div className={styles.content_title}>В избранном</div>
                <div className={styles.content_text}>{statsInfo.favorites}</div>
              </div>
            </div>
          )} */}
        </div>
      );
    });
  }, [stats, styles]);

  if (loading) {
    return (
      <div className={styles.root}>
        <CircularProgress size="20px" />
      </div>
    );
  }

  if (!stats || !stats.links) {
    return null;
  }

  return (
    <div className={styles.root}>
      {/* {stats?.stats && (
        <div className={styles.dates}>
          Даты: {getFormattedDate(stats.stats.start_date)} -{' '}
          {getFormattedDate(stats.stats.end_date)}
        </div>
      )} */}
      <div>{items}</div>
      {stats.code && (
        <div className={styles.containerButton}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://marketing-stats.villagio-realty.ru/ui/v1/realty-object-report/${stats.code}`}
          >
            Расширенная статистика (экспериментально)
          </a>
        </div>
      )}
    </div>
  );
};

const FormAPI = ({ formApiRef }) => {
  const formApi = useForm();

  useEffect(() => {
    formApiRef.current = formApi;
  }, [formApi, formApiRef]);

  return null;
};

export const FormObjects = (props) => {
  const { permissions, location, id, authId, record } = props;
  const notify = useNotify();
  const [visibleDescriptionPdf, setVisibleDescriptionPdf] = useState(false);
  const formApiRef = useRef(null);

  const handleChangeFunnelStep = useCallback((event) => {
    const value = event.target.value;
    if ((Number(value) === 800 || Number(value) === 1200) && formApiRef.current) {
      formApiRef.current.change('excludeFromFeeds', true);
    }
  }, []);

  useEffect(() => {
    // fix for map!
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  // const hasRoleAdmin = useMemo(() => {
  //   return checkRoleAdmin(permissions);
  // }, [permissions]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const disableEdit = useMemo(() => {
    if (hasRoleAdminOrSupervisor || !authId) {
      return false;
    }

    if (record && record.manager && record.manager.authId && authId === record.manager.authId) {
      return false;
    }

    return true;
  }, [authId, hasRoleAdminOrSupervisor, record]);

  const [type, setType] = useState('');

  const handleBlurOldPrice = useCallback(
    (event) => {
      const oldPriceValue = event.target.value;

      if (oldPriceValue && oldPriceValue < minOldPriceValue) {
        setTimeout(() => {
          notify(`Вы уверены, что цена меньше миллиона?`, { type: 'info' });
        }, 200);
      }
    },
    [notify],
  );

  // const [baseObject, setBaseObject] = useState(record.realtyBaseObject);

  const [sector, setSector] = useState();
  const handleChangeSector = useCallback((newValue) => {
    const fetch = async () => {
      setSector();
      const result = await dataProvider.getOne('RealtySectors', { id: newValue?.id });
      if (result.data) {
        setSector(result.data);
      }
    };
    if (newValue?.id) {
      fetch();
    }

    if (!newValue) {
      setSector();
    }
  }, []);

  useEffect(() => {
    if (record.realtySector) {
      setSector(record.realtySector);
    }
  }, [record]);

  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  const oldSysStatusRef = useRef(record.sysStatus);

  const handleChangeSysStatus = useCallback(
    (event) => {
      const newValue = event.target.value;
      if (record.id && Number(oldSysStatusRef.current) === 200 && Number(newValue) === 100) {
        handleOpenDialog();
      }
      oldSysStatusRef.current = newValue;
    },
    [handleOpenDialog, record],
  );

  // eslint-disable-next-line no-unused-vars
  const [externalExpertDirectionId, setExternalExpertDirectionId] = useState();
  const [expertDirection, setExpertDirection] = useState(record.expertSalesDirectionId);

  const handleChangeExpert = useCallback(
    (newValue) => {
      let resultValue;
      if (newValue?.salesDirectionId && !expertDirection) {
        resultValue = newValue.salesDirectionId;
      }
      setExternalExpertDirectionId(resultValue);
    },
    [expertDirection],
  );

  const handleChangeExpertDirectionId = useCallback((newValue) => {
    setExpertDirection(newValue);
  }, []);

  return (
    <TabbedForm {...props} hideSave={disableEdit}>
      <FormTab label="Лот">
        <FormCard>
          <FormAPI formApiRef={formApiRef} />
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <AutocompleteWithFormRow
            {...props}
            source="realtyBaseObjects"
            resourceName="RealtyBaseObjects"
            resourceId="realtyBaseObjectId"
            additionalSource="realtyBaseObject"
            // onChange={(newBaseObject) => {
            //   setBaseObject(newBaseObject);
            // }}
            componentProps={{
              label: 'Базовые объекты',
              multiple: true,
              filterSelectedOptions: true,
              fullDynamic: 'NameContains',
            }}
          />
          {/* {baseObject && (
            <ListItem record={baseObject} hasButtons={false} resource="RealtyBaseObjects" />
          )} */}
          <FormRow>
            <TextInput source="friendlyId" label="Номер лота" validate={validates.friendlyId} />
          </FormRow>
          <ExpertSelect {...props} onChange={handleChangeExpert} />
          <AutocompleteWithFormRow
            {...props}
            source="expertSalesDirection"
            resourceName="SalesDirections"
            resourceId="expertSalesDirectionId"
            onChange={handleChangeExpertDirectionId}
            validate={validates.required}
            componentProps={{
              label: 'Направление эксперта',
              externalValue: externalExpertDirectionId,
            }}
          />
          <OwnerSelect {...props} />
          <RepresentativeSelect {...props} />
          <FormRow>
            <BooleanInput label="Проживает собственник" source="isOwnerInside" />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.nameObject} />
          </FormRow>
          <FormRow>
            <TextInput
              source="shortName"
              label="Краткое название"
              validate={validates.shortNameObject}
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="objectTypes"
            resourceName="RealtyObjectTypes"
            resourceId="objectTypeId"
            additionalSource="objectType"
            componentProps={{
              label: 'Тип объекта',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow maxWidth={800}>
            <TextInput source="comment" label="Комментарий" multiline rows={6} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
              onChange={handleChangeSysStatus}
            />
          </FormRow>
          <FormTitle>Статус объекта</FormTitle>
          <FormDataConsumer>
            {({ formData }) => {
              const isSold =
                Number(formData.funnelStep) === 600 ||
                Number(formData.funnelStep) === 700 ||
                Number(formData.funnelStep) === 900 ||
                Number(formData.funnelStep) === 1000 ||
                Number(formData.funnelStep) === 1100;
              const disabled = isSold;

              if (formData.lastPreSoldDate) {
                formData.lastSoldDate = formData.lastPreSoldDate;
              } else {
                formData.lastSoldDate = '';
              }

              const soldWithUs = Number(formData.funnelStep) === 900;

              if (!soldWithUs) {
                delete formData.soldPriceRub;
              }

              const hasLastPreSoldDate =
                Number(formData.funnelStep) === 600 ||
                Number(formData.funnelStep) === 700 ||
                Number(formData.funnelStep) === 900 ||
                Number(formData.funnelStep) === 1000 ||
                Number(formData.funnelStep) === 1100;

              return (
                <>
                  <FormRow>
                    <BooleanInput label="Для продажи" source="isForSale" disabled={disabled} />
                  </FormRow>
                  <FormRow>
                    <BooleanInput label="Для аренды" source="isForRent" disabled={disabled} />
                  </FormRow>
                  <FormRow>
                    <ConstantSelect
                      source="funnelStep"
                      objectChoices={getFunnelSteps(hasRoleAdminOrSupervisor)}
                      label="Этап воронки"
                      allowEmpty
                      validate={validates.required}
                      onChange={handleChangeFunnelStep}
                    />
                    {/* {formData.excludeFromFeeds === false && hasError && (
                      <p
                        style={{
                          color: '#f44336',
                          marginLeft: '14px',
                          marginRight: '14px',
                          fontSize: '0.75rem',
                          marginTop: '3px',
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        }}
                      >
                        Не забудьте снять флаг &quot;Использовать в фидах&quot;!
                      </p>
                    )} */}
                  </FormRow>
                  {hasLastPreSoldDate && (
                    <FormRow>
                      <DateTimePickerInput
                        source="lastPreSoldDate"
                        label="Дата продажи"
                        helperText="Когда в последний раз продан по мнению экспертов"
                        validate={validates.lastPreSoldDate}
                      />
                    </FormRow>
                  )}
                  {soldWithUs && (
                    <FormRow>
                      <NumberInput
                        source="soldPriceRub"
                        label="Цена за которую продан"
                        helperText="Вводить в рублях!"
                        min={0}
                        validate={validates.required}
                      />
                    </FormRow>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
          <FormTitle>Тип договора</FormTitle>
          <FormRow>
            <ConstantSelect
              source="contractType"
              defaultValue={300}
              objectChoices={objectContractTypes}
              label="Тип договора"
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput source="contractUntil" label="Срок действия договора" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="expoType"
              defaultValue={100}
              objectChoices={expoTypes}
              label="Тип продажи"
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="residentialDealType"
              allowEmpty
              objectChoices={residentialDealTypes}
              label="Тип сделки"
            />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              source="feePercents"
              label="Комиссия за продажу"
              validate={validates.feePercents}
              InputProps={{
                endAdornment: '%',
              }}
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Доверенность на агента" source="residentialIsAgentTrusted" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Сайт">
        <FormCard>
          <FormRow>
            <BooleanInput label="Спецпредложение" source="isWowOffer" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Уникальный объект" source="isUnique" />
          </FormRow>
          {/* <AutocompleteWithFormRow
          source="marketingTags"
          resourceName="marketingTags"
          resourceId="marketingTagId"
          additionalSource="marketingTag"
          componentProps={{
            label: 'Маркетинговые фишки',
            multiple: true,
            filterSelectedOptions: true,
          }}
        /> */}
          <FormTitle>Полное описание</FormTitle>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <FormRow maxWidth={800}>
            <TextInput
              source="shortDescription"
              label="Описание под заголовком"
              multiline
              rows={6}
              validate={validates.shortDescriptionObject}
            />
          </FormRow>
          <FormRow maxWidth={800}>
            <Link
              color="primary"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setVisibleDescriptionPdf((visible) => !visible);
              }}
            >
              Добавить описание для презентации
            </Link>
          </FormRow>
          {visibleDescriptionPdf && (
            <FormRow maxWidth={800}>
              <TextInput
                source="pdfDescription"
                label="Описание для презентации"
                multiline
                rows={6}
                validate={validates.pdfDescriptionObject}
              />
            </FormRow>
          )}
          <FormRow isLast>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
              disabled={!hasRoleAdminOrSupervisor}
            />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Цены">
        <FormCard>
          <FormRow>
            <ConstantSelect source="currencyType" objectChoices={currencyTypes} label="Валюта" />
          </FormRow>
          <FormRow>
            <NumberInput
              source="oldPrice"
              label="Цена продажи"
              onBlur={handleBlurOldPrice}
              InputProps={{
                inputComponent: СustomNumericInput,
              }}
            />
          </FormRow>
          <FormRow>
            <NumberInput
              source="oldRentPrice"
              label="Цена аренды"
              InputProps={{
                inputComponent: СustomNumericInput,
              }}
            />
          </FormRow>
          <FormTitle>Изменение цены</FormTitle>
          <FormRow>
            <BooleanInput label="Цена снижена" source="isDiscounted" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="liquidity"
              objectChoices={liquidityTypes}
              label="Тип ликидности"
              allowEmpty
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              const liquidity = formData.liquidity;
              if (!liquidity) {
                delete formData.liquidityReasons;
              }

              return (
                liquidity && (
                  <AutocompleteWithFormRow
                    {...props}
                    source="liquidityReasons"
                    resourceName="realtyLiquidityReason"
                    resourceId="realtyLiquidityReasonId"
                    additionalSource="realtyLiquidityReason"
                    componentProps={{
                      label: 'Причины ликвидности',
                      multiple: true,
                      filterSelectedOptions: true,
                    }}
                  />
                )
              );
            }}
          </FormDataConsumer>
          <AutocompleteWithFormRow
            {...props}
            source="objectDiscounts"
            resourceName="RealtyObjectDiscounts"
            resourceId="objectDiscountId"
            additionalSource="objectDiscount"
            componentProps={{
              label: 'Сумма скидки',
              multiple: true,
              filterSelectedOptions: true,
              getOptionLabel: (option) => `${option.id} / ${option.slug} / ${option.name}`,
              renderOption: (option) => <DiscountOption option={option} />,
              providerParams: { filter: { FlatDetails: 300 } },
            }}
          />
          <FormRow>
            <BooleanInput label="Есть обременение" source="commercialIsEncumbered" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="residentialContractType"
              objectChoices={residentialContractTypes}
              label="Форма договора"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Переуступка" source="residentialIsRightsShifted" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="residentialOwnershipType"
              objectChoices={residentialOwnershipTypes}
              label="Тип собственности"
              allowEmpty
            />
          </FormRow>
          {id && (
            <>
              <FormTitle>Аналитика цены</FormTitle>
              <FormRow>
                <ObjectPriceChart {...props} />
              </FormRow>
            </>
          )}
        </FormCard>
      </FormTab>
      <FormTab label="Локация">
        <FormCard>
          <FormRow maxWidth={800}>
            <TextInput
              source={record.realtyGroupId ? 'realtyGroup.address' : 'address'}
              label={record.realtyGroupId ? 'Адрес поселка' : 'Адрес'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <Map {...props} />
          <FormRow>
            <BooleanInput
              label="Скрыть координаты"
              source="isHideAnonCoords"
              helperText="Отображать координаты комплекса"
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="realtyGroup"
            resourceName="RealtyGroups"
            resourceId="realtyGroupId"
            // validate={validates.objectVillage}
            componentProps={{
              label: 'Комплекс',
              fullDynamic: 'NameContains',
              getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="ownSalesDirection"
            resourceName="SalesDirections"
            resourceId="ownSalesDirectionId"
            validate={validates.required}
            componentProps={{
              label: 'Направление',
            }}
          />
          <FormDataConsumer>
            {({ formData }) => {
              const villageId = formData.realtyGroupId;
              if (!villageId) {
                delete formData.realtySector;
                delete formData.realtySectorId;
              }

              return (
                villageId && (
                  <AutocompleteWithFormRow
                    {...props}
                    onChange={handleChangeSector}
                    source="realtySector"
                    resourceName="RealtySectors"
                    resourceId="realtySectorId"
                    componentProps={{
                      label: 'Сектор',
                      providerParams: { filter: { RealtyGroup: villageId } },
                    }}
                  />
                )
              );
            }}
          </FormDataConsumer>
          {sector && sector.manager && (
            <>
              <FormTitle>Эксперт квартала</FormTitle>
              <FormRow>
                <ListItem type="people" record={sector.manager} hasButtons={false} isMiniCard />
              </FormRow>
            </>
          )}
          {sector && sector.serviceManager && (
            <>
              <FormTitle>Сервисный эксперт квартала</FormTitle>
              <FormRow>
                <ListItem
                  type="people"
                  record={sector.serviceManager}
                  hasButtons={false}
                  isMiniCard
                />
              </FormRow>
            </>
          )}
          <AutocompleteWithFormRow
            {...props}
            source="realtyGroupGeoTags"
            resourceName="GeoTags"
            resourceId="geoTagId"
            additionalSource="geoTag"
            componentProps={{
              label: 'Локация',
              disabled: true,
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <TextInput source="residentialTsHubNearest" label="Жд станция или метро" />
          </FormRow>
          <FormRow>
            <TextInput
              source="residentialTsHubReachMinutes"
              label="Расстояние до станции метро, мин"
            />
          </FormRow>
          <FormRow>
            <TextInput source="residentialSeaReachMinutes" label="Расстояние до моря, мин" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="residentialTsHubReachWay"
              objectChoices={residentialTransportTypes}
              label="Как добраться до станции метро"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="residentialSeaReachWay"
              objectChoices={residentialTransportTypes}
              label="Как добраться до моря"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <NumberInput min={0} source="distanceToMkad" label="Расстояние до МКАД" />
          </FormRow>
          {/* <FormRow>
          <NumberInput
            min={0}
            source="realtyGroup.distance"
            label="Расстояние до МКАД у поселка"
            disabled
          />
        </FormRow> */}
          <FormRow>
            <TextInput
              source={
                record.realtyGroupId ? 'realtyGroup.addressPostalIndex' : 'addressPostalIndex'
              }
              label={record.realtyGroupId ? 'Индекс поселка' : 'Индекс'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source={record.realtyGroupId ? 'realtyGroup.addressCountry' : 'addressCountry'}
              label={record.realtyGroupId ? 'Страна поселка' : 'Страна'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source={record.realtyGroupId ? 'realtyGroup.addressRegion' : 'addressRegion'}
              label={record.realtyGroupId ? 'Регион/Область поселка' : 'Регион/Область'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source={record.realtyGroupId ? 'realtyGroup.addressLocality' : 'addressLocality'}
              label={record.realtyGroupId ? 'Город поселка' : 'Город'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source={record.realtyGroupId ? 'realtyGroup.addressStreet' : 'addressStreet'}
              label={record.realtyGroupId ? 'Улица поселка' : 'Улица'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <FormRow>
            <TextInput source="addressHouseNo" label="Дом" />
          </FormRow>
          <FormRow>
            <TextInput
              source={record.realtyGroupId ? 'realtyGroup.addressBlock' : 'addressBlock'}
              label={record.realtyGroupId ? 'Корпус поселка' : 'Корпус'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <FormRow isLast>
            <TextInput
              source={record.realtyGroupId ? 'realtyGroup.addressBuilding' : 'addressBuilding'}
              label={record.realtyGroupId ? 'Строение поселка' : 'Строение'}
              disabled={Boolean(record.realtyGroupId)}
            />
          </FormRow>
          <FormRow>
            <TextInput label="Номер квартиры" source="residentialFlatNumber" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Описание">
        <FormCard>
          <FormTitle>Общее</FormTitle>
          <FormRow>
            <NumberInput min={0} source="residentialElevators" label="Пассажирских лифтов" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Мусоропровод" source="residentialIsGarbageChute" />
          </FormRow>
          <FormRow>
            <NumberInput min={0} source="residentialLastMajorRepair" label="Кап.ремонт" />
          </FormRow>
          <FormRow>
            <NumberInput min={0} source="residentialFreightElevators" label="Грузовых лифтов" />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              source="residentialBaseFloorCellHeight"
              label="Высота 1-го этажа"
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="residentialParkingType"
              objectChoices={residentialParkingTypes}
              label="Парковка"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Консьерж" source="residentialIsConcierge" />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Новый"
              source="isNew"
              helperText="Автоматическое поле, чтобы изменить надо использовать поле ниже"
              disabled
            />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Новый (управляемый)"
              source="isOverridedNew"
              helperText="Перезаписывает поле новый (для продления флага новый)"
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Важный" source="isImportant" />
          </FormRow>
          <AutocompleteWithFormRow
            source="realtyObjectTags"
            resourceName="realtyObjectTags"
            resourceId="realtyObjectTagId"
            additionalSource="realtyObjectTag"
            {...props}
            componentProps={{
              label: 'Тэги',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <ConstantSelect
              source="isInitialSale"
              objectChoices={initialSales}
              label="Первичка или вторичка?"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="luxuryClass"
              objectChoices={luxuryClasses}
              label="Класс объекта"
              allowEmpty
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="currentObjectState"
            resourceName="RealtyObjectStates"
            resourceId="currentObjectStateId"
            componentProps={{
              label: 'Состояние',
            }}
          />
          <FormRow>
            <ConstantSelect
              source="repairType"
              objectChoices={repairType}
              label="Ремонт"
              allowEmpty
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="objectMaterials"
            resourceName="RealtyObjectMaterials"
            resourceId="objectMaterialId"
            additionalSource="objectMaterial"
            componentProps={{
              label: 'Материалы',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="communications"
            resourceName="RealtyObjectCommunications"
            resourceId="realtyObjectCommunicationId"
            additionalSource="realtyObjectCommunication"
            componentProps={{
              label: 'Коммуникации',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <NumberInput label="кВт" source="commercialElectroPower" min={0} />
          </FormRow>
          <FormTitle>Участок</FormTitle>
          <FormRow>
            <TextInput
              source="plottage"
              label="Площадь"
              InputProps={{
                endAdornment: <span>сот.</span>,
              }}
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="terrainTags"
            resourceName="TerrainTags"
            resourceId="terrainTagId"
            additionalSource="terrainTag"
            componentProps={{
              label: 'Особенности участка',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormDataConsumer>
            {({ formData }) => {
              const isPlot = chechIsPlot(formData);
              setType(isPlot ? 'plot' : '');

              return (
                <>
                  {!isPlot && (
                    <>
                      <FormTitle>Дом | Квартира</FormTitle>
                      <FormRow>
                        <ConstantSelect
                          source="residentialRoomType"
                          objectChoices={residentialRoomTypes}
                          label="Тип планировки"
                          allowEmpty
                        />
                      </FormRow>
                      <FormRow>
                        <TextInput source="residentialCellHeight" label="Высота потолка" />
                      </FormRow>
                      <FormRow>
                        <TextInput
                          source="totalFoorArea"
                          label="Общая площадь"
                          InputProps={{
                            endAdornment: (
                              <span>
                                м<sup style={{ fontSize: '10px' }}>2</sup>
                              </span>
                            ),
                          }}
                        />
                      </FormRow>
                      <FormRow>
                        <TextInput
                          source="usefulFoorArea"
                          label="Жилая площадь"
                          InputProps={{
                            endAdornment: (
                              <span>
                                м<sup style={{ fontSize: '10px' }}>2</sup>
                              </span>
                            ),
                          }}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput
                          min={1}
                          source="floorsInBuilding"
                          label="Количество этажей в здании"
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={1} source="floors" label="Этажей лота" />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={1} source="floor" label="Этаж лота" />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={1} source="loggias" label="Кол-во лоджий" />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={1} source="balconies" label="Кол-во балконов" />
                      </FormRow>
                      <FormRow>
                        <NumberInput min="0" source="year" label="Год постройки" />
                      </FormRow>
                      <AutocompleteWithFormRow
                        {...props}
                        source="realtyObjectStyle"
                        resourceName="RealtyObjectStyles"
                        resourceId="realtyObjectStyleId"
                        componentProps={{
                          label: 'Архитектурный стиль',
                        }}
                      />
                      <FormRow>
                        <ConstantSelect
                          source="windowView"
                          objectChoices={windowViewTypes}
                          label="Вид из окон"
                          allowEmpty
                        />
                      </FormRow>
                      <FormTitle>Комнаты</FormTitle>
                      <FormRow>
                        <ConstantSelect
                          source="residentialBathType"
                          objectChoices={residentialBathTypes}
                          label="Ванна"
                          allowEmpty
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={0} source="terraces" label="Количество террас" />
                      </FormRow>
                      <FormRow>
                        <ConstantSelect
                          source="residentialBathroomType"
                          objectChoices={residentialBathroomTypes}
                          label="Сан.узел"
                          allowEmpty
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={0} source="rooms" label="Кол-во комнат" />
                      </FormRow>
                      <FormRow>
                        <TextInput
                          source="roomsArea"
                          label="Площадь комнат"
                          helperText="Например: «18.5-14-15»"
                        />
                      </FormRow>
                      <AutocompleteWithFormRow
                        {...props}
                        source="realtyObjectRoomsTypes"
                        resourceName="RealtyObjectRoomsTypes"
                        resourceId="realtyObjectRoomsTypeId"
                        additionalSource="realtyObjectRoomsType"
                        componentProps={{
                          label: 'Тип комнат',
                          multiple: true,
                          filterSelectedOptions: true,
                        }}
                      />
                      <FormRow>
                        <TextInput
                          source="kitchenArea"
                          label="Площадь кухни"
                          InputProps={{
                            endAdornment: (
                              <span>
                                м<sup style={{ fontSize: '10px' }}>2</sup>
                              </span>
                            ),
                          }}
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={0} source="bedrooms" label="Спальни" />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={0} source="masterBeds" label="Мастер-спальни" />
                      </FormRow>
                      {/* <FormRow>
                        <NumberInput min={0} source="bathrooms" label="Ванных комнат" />
                      </FormRow> */}
                      <FormRow>
                        <NumberInput min={0} source="wardrobes" label="Гардеробные" />
                      </FormRow>
                      <FormRow>
                        <NumberInput
                          min={0}
                          source="wardrobeInBeds"
                          label="Гардеробные в спальнях"
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={0} source="bathrooms" label="Ванные" />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={0} source="bathInBeds" label="Ванные в спальнях" />
                      </FormRow>
                      <FormRow>
                        <NumberInput min={0} source="lavatories" label="Туалеты" />
                      </FormRow>
                      <AutocompleteWithFormRow
                        {...props}
                        source="objectFeatures"
                        resourceName="RealtyObjectFeatures"
                        resourceId="objectFeatureId"
                        additionalSource="objectFeature"
                        componentProps={{
                          label: 'Преимущества',
                          multiple: true,
                          filterSelectedOptions: true,
                        }}
                      />
                    </>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
          <FormTitle>Коммерческая</FormTitle>
          <FormRow>
            <ConstantSelect
              source="commercialType"
              objectChoices={yuCommercialTypes}
              label="Тип"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="commercialMainPurpose"
              objectChoices={commercialMainPurposes}
              label="Основное назначение"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="commercialState"
              objectChoices={commercialStates}
              label="Состояние"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="commercialEntranceType"
              objectChoices={commercialEntranceTypes}
              label="Тип входа"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="commercialClass"
              objectChoices={commercialClasses}
              label="Класс здания"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="commercialPurpose"
              objectChoices={commercialPurposes}
              label="Назначение здания"
              allowEmpty
            />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Продвижение">
        <FormCard>
          <FormTitle>Фиды</FormTitle>
          <AutocompleteWithFormRow
            {...props}
            source="realtyFeeds"
            resourceName="RealtyFeeds"
            resourceId="realtyFeedId"
            additionalSource="realtyFeed"
            componentProps={{
              label: 'Площадки фидов',
              multiple: true,
              filterSelectedOptions: true,
              providerParams: { filter: { IsSystem: true, Status: 200 } },
            }}
          />
          {id && <MarketingStats id={id} />}
          <FormRow>
            <BooleanInput
              format={(v) => !v}
              parse={(v) => !v}
              label="Использовать в фидах"
              source="excludeFromFeeds"
              defaultValue={true}
            />
          </FormRow>

          {/* <FormRow>
            <BooleanInput
              label="Всегда продвигать в фидах"
              source="promoteInFeeds"
              disabled={!hasRoleAdminOrSupervisor}
            />
          </FormRow> */}
          <FormTitle>Общее</FormTitle>
          <FormRow>
            <TextInput source="cadastralNumber" label="Кадастровый номер объекта" />
          </FormRow>
          <FormRow>
            <TextInput source="residentialRosreestrId" label="Номер регистрации в Росреестре" />
          </FormRow>
          <FormRow>
            <TextInput
              source="anyJsonDictionary.lotCadastralNumber"
              label="Кадастровый номер участка"
            />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Можно курить"
              source="anyJsonDictionary.smokingAllowed"
              helperText="smokingAllowed"
            />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Можно детей"
              source="anyJsonDictionary.childrenAllowed"
              helperText="childrenAllowed"
            />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Можно животных"
              source="anyJsonDictionary.petsAllowed"
              helperText="petsAllowed"
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="anyJsonDictionary.landStatus"
              objectChoices={landStatuses}
              label="Статус земли"
              helperText="Status"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="anyJsonDictionary.vatType"
              objectChoices={vatTypes}
              label="Тип НДС"
              helperText="vatType"
              defaultValue={100}
            />
          </FormRow>
          <FormTitle>Циан, Домклик</FormTitle>
          <FormRow>
            <NumberInput
              source="anyJsonDictionary.HouseID"
              label="ID Корпус"
              helperText="HouseID"
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="anyJsonDictionary.CplModeration"
              label="CPL модерация"
              helperText="CplModeration. Заполняются данными дольщика из ДДУ. Для физического лица необходимо указать ФИО, для юридического лица — ИНН."
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="heatingType"
              objectChoices={heatingType}
              label="Отопление"
              helperText="HeatingType"
              allowEmpty
            />
          </FormRow>
          {/* <FormRow>
            <ConstantSelect
              source="cianService"
              objectChoices={cianService}
              label="ServicesEnum"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="cianExcludedService"
              objectChoices={cianExcludedService}
              label="ExcludedServicesEnum"
              allowEmpty
            />
          </FormRow> */}
          <FormRow>
            <NumberInput
              min={0}
              source="auctionBet"
              label="Ставка на объявление"
              helperText="Auction	Bet"
            />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              source="agentFee"
              label="Комиссия от другого агента"
              helperText="AgentFee"
              defaultValue={0}
              InputProps={{
                endAdornment: '%',
              }}
            />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              source="clientFee"
              label="Комиссия от прямого клиента"
              helperText="ClientFee"
              defaultValue={0}
              InputProps={{
                endAdornment: '%',
              }}
            />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              source="anyJsonDictionary.deposit"
              label="Залог собственнику"
              helperText="Deposit"
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="anyJsonDictionary.PersonType"
              objectChoices={personTypes}
              label="Тип лица"
              allowEmpty
              helperText="PersonType"
            />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              source="anyJsonDictionary.agentBonus"
              label="Бонус агенту"
              helperText="AgentBonus"
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="anyJsonDictionary.commercialType"
              objectChoices={commercialTypes}
              label="Типы коммерческой недвижимости"
              allowEmpty
              helperText="CommercialType"
            />
          </FormRow>
          <FormTitle>Авито</FormTitle>
          <FormRow>
            <ConstantSelect
              source="avitoListingFee"
              objectChoices={avitoListingFee}
              label="Вариант платного размещения"
              helperText="ListingFee"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Ключи в офисе продаж" source="isKeysInOffice" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Показы согласовывать заранее" source="isCoordinatedDemo" />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Онлайн-показ"
              helperText="SafeDemonstration"
              source="safeDemonstration"
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="anyJsonDictionary.leaseDeposit"
              objectChoices={leaseDeposit}
              label="Залог"
              helperText="leaseDeposit"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              max={999999}
              source="anyJsonDictionary.depositAmount"
              label="Сумма залога"
              helperText="depositAmount"
              validate={validates.depositAmount}
            />
          </FormRow>
          <FormRow>
            <NumberInput
              source="anyJsonDictionary.leaseCommissionSize"
              label="Размер комиссии"
              max={200}
              InputProps={{
                endAdornment: '%',
              }}
              helperText="leaseCommissionSize"
              validate={validates.leaseCommissionSize}
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="anyJsonDictionary.utilityMeters"
              objectChoices={utilityMeters}
              label="Оплата по счетчикам"
              helperText="utilityMeters"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="anyJsonDictionary.otherUtilities"
              objectChoices={utilityMeters}
              label="Другие ЖКУ"
              helperText="otherUtilities"
              allowEmpty
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              if (
                formData.anyJsonDictionary &&
                formData.anyJsonDictionary?.otherUtilities !== '100'
              ) {
                delete formData.anyJsonDictionary.otherUtilitiesPayment;
              }
              return (
                <>
                  {formData?.anyJsonDictionary?.otherUtilities === '100' && (
                    <>
                      <FormRow>
                        <NumberInput
                          source="anyJsonDictionary.otherUtilitiesPayment"
                          label="Плата за другие ЖКУ в месяц"
                          helperText="otherUtilitiesPayment"
                        />
                      </FormRow>
                    </>
                  )}
                </>
              );
            }}
          </FormDataConsumer>
          <FormRow>
            <NumberInput
              min={0}
              source="anyJsonDictionary.newDevelopmentId"
              label="ID новостройки"
              helperText="NewDevelopmentId, https://autoload.avito.ru/format/New_developments.xml"
            />
          </FormRow>
          <FormTitle>Яндекс</FormTitle>
          <FormRow>
            <ConstantSelect
              source="yandexVas"
              objectChoices={yandexVas}
              label="Дополнительная опция по продвижению объявления"
              helperText="vas"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              source="yandexVasDate"
              label="Дата для продвижения объявления"
              helperText="vasDate"
            />
          </FormRow>
          <FormRow isLast>
            <BooleanInput
              source="isPmg"
              label="Возможность ПМЖ"
              helperText="pmg"
              defaultValue={true}
            />
          </FormRow>
          <FormTitle>Seo</FormTitle>
          <SeoTab isLast={false} />
        </FormCard>
      </FormTab>
      <FormTab label="Изображения">
        <ListImagesOrVideos id={id} hasMultiple parentType={type} hasTemplateName />
      </FormTab>
      <FormTab label="Видео">
        <ListImagesOrVideos id={id} isVideo parentType={type} />
      </FormTab>
      <FormTab label="Инфраструктура">
        <ListInfrastructures />
      </FormTab>
      {id && (
        <FormTab label="Комментарии">
          <CommentsTab id={id} type={100} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={200} location={location} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Показы">
          <DynamicList
            fetchOptions={{
              resource: 'Demos',
              filter: { RealtyObjects: record.id },
            }}
            contentElement={List}
            contentOptions={{
              type: 'show',
              hasButtons: false,
              isNewItem: true,
            }}
          />
        </FormTab>
      )}
      {/* {id && (
        <FormTab label="Новая локация">
          <MyYMap />
        </FormTab>
      )} */}
      <UnpublishObjectDialog changeOpenRef={changeOpenRef} />
    </TabbedForm>
  );
};
