import React, { useCallback, useMemo, useState } from 'react';
import { useInput, FieldTitle } from 'ra-core';
import { InputHelperText } from 'react-admin';

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import { makeStyles } from '@material-ui/core/styles';
import { mergeClasses } from '../../../utils';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }

  getDateTimePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: this.locale });
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0',
    width: '100%',
  },
  filled: {
    '& > div:not(.Mui-disabled)': {
      backgroundColor: theme.palette.primaryNew.light,
    },
    '& > div:hover:not(.Mui-disabled):not(.Mui-error)': {
      backgroundColor: theme.palette.primaryNew.light100,
    },
  },
}));

const Picker = ({ PickerComponent, ...fieldProps }) => {
  const {
    options,
    label,
    source,
    resource,
    className,
    helperText = '',
    providerOptions,
    disabled,
    onChange,
    isRequired,
    defaultValue,
    onBlur,
    name,
    error,
    inputVariant = 'outlined',
    value: propValue,
    isControlled,
    size,
  } = fieldProps;

  const classes = useStyles();
  const [value, setValue] = useState(defaultValue ? new Date(defaultValue) : null);

  const handleChange = useCallback(
    (_value) => {
      const newValue = _value || null;
      let newDate = '';

      if (newValue) {
        newDate = new Date(newValue);
        newDate = new Date(newDate.setSeconds(0, 0)).toISOString();
      }
      onChange?.(newDate, name);
      setValue(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const handleBlur = useCallback(
    (_value) => {
      onBlur?.(_value);
    },
    [onBlur],
  );

  const isFilled = useMemo(() => {
    return inputVariant === 'filled' && Boolean(value);
  }, [inputVariant, value]);

  return (
    <div className="picker">
      <MuiPickersUtilsProvider {...providerOptions}>
        <PickerComponent
          {...options}
          disabled={disabled}
          size={size}
          inputVariant={inputVariant}
          cancelLabel="Отменить"
          okLabel="Выбрать"
          clearable={true}
          clearLabel="Очистить"
          label={
            <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
          }
          margin="normal"
          className={mergeClasses(classes.root, isFilled && classes.filled, className)}
          value={isControlled ? propValue : value}
          onChange={(date) => handleChange(date)}
          onBlur={handleBlur}
          helperText={helperText}
          isRequired={isRequired}
          error={error}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

Picker.defaultProps = {
  isRequired: false,
  options: {},
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  providerOptions: {
    utils: LocalizedUtils,
    locale: ruLocale,
  },
};

export const RAPicker = (props) => {
  const { validate, onChange, source, onBlur, helperText = '', defaultValue } = props;
  const { input, meta, isRequired } = useInput({ validate, source, defaultValue });
  const { touched, error, submitError } = meta;

  const handleChange = useCallback(
    (value, name) => {
      input.onChange(value);
      onChange?.(value, name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const handleBlur = useCallback(() => {
    input.onBlur(input.value ? new Date(input.value).toISOString() : '');
    onBlur?.();
  }, [input, onBlur]);

  const currentDefaultValue = useMemo(() => {
    return input.value || defaultValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Picker
      inputVariant="filled"
      {...props}
      onChange={handleChange}
      isRequired={isRequired}
      meta={meta}
      defaultValue={currentDefaultValue}
      onBlur={handleBlur}
      error={!!(touched && error)}
      helperText={
        <InputHelperText touched={touched} error={error || submitError} helperText={helperText} />
      }
    />
  );
};

const pickers = {
  date: { component: DatePicker, options: { format: 'dd.MM.yyyy' } },
  time: { component: TimePicker, options: { ampm: false } },
  dateTime: { component: DateTimePicker, options: { format: 'dd.MM.yyyy HH:mm', ampm: false } },
};

export const DateTimePickerInput = (props) => {
  const { pickerType = 'dateTime', isRA = true } = props;

  const PickerProps = useMemo(() => {
    if (!(pickerType in pickers)) {
      alert('Неверный тип пикера');
    }
    return pickers[pickerType];
  }, [pickerType]);

  const Component = useMemo(() => {
    if (isRA) {
      return RAPicker;
    }

    return Picker;
  }, [isRA]);

  return (
    <Component PickerComponent={PickerProps.component} options={PickerProps.options} {...props} />
  );
};
