import React, { useMemo, useCallback } from 'react';
import { Datagrid, TextField } from 'react-admin';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ListBase } from '../../../components/ListBase';
import { getObjectLink } from '../../../utils';
import { StatusesField } from '../../../components/RAFields/StatusesField';
import { ObjectsSelectedMenu } from '../../../components/app/SelectedMenus';
import {
  PhotoField,
  NameField,
  AuthorField,
  PriceField,
  SoldField,
} from '../../../components/RAFields';
import { ObjectsMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperPhotoField = ({ record }) => {
  const path = useMemo(() => {
    if (record?.images?.length > 0) {
      const foundItem = record.images.find((image) => {
        return image.imageType === 200;
      });

      return foundItem?.path || record.images[0].path;
    }
  }, [record]);

  return <PhotoField path={path} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name, friendlyId } = record;

  const link = useMemo(() => {
    return getObjectLink(record);
  }, [record]);

  return <NameField name={name} id={id} additionalId={friendlyId} link={link} />;
};

const TableObjects = ({ permissions }) => {
  return (
    <Datagrid hasBulkActions>
      <WrapperPhotoField label="Фото" sortable={false} />
      <WrapperNameField source="name" label="Название" sortable={false} />
      <TextField source="realtyGroup.name" label="Комплекс" sortable={false} />
      <TextField source="totalFoorArea" label="Площадь (м2)" sortable={false} />
      <TextField source="plottage" label="Площадь участка (сот.)" sortable={false} />
      <PriceField source="currentPrice" label="Цена" sortable={false} />
      <PriceField source="currentRentPrice" label="Цена аренды" sortable={false} />
      <TextField source="currentObjectState.name" label="Состояние" sortable={false} />
      <StatusesField source="sysStatus" label="Статус публикации" sortable={false} />
      <SoldField source="isSold" label="Статус" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <ObjectsMenu permissions={permissions} />
    </Datagrid>
  );
};

const CardsObjects = ({ menuItem }) => {
  return <RAList type="object" menuItem={menuItem} />;
};

export const ListObjects = (props) => {
  const { resource, permissions } = props;

  const menuItem = useCallback(
    (record) => {
      return (
        <ObjectsMenu
          record={record}
          resource={resource}
          permissions={permissions}
          icon={<MoreVertIcon />}
        />
      );
    },
    [permissions, resource],
  );

  return (
    <ListBase
      {...props}
      hasToggleView
      selectedMenuOptions={ObjectsSelectedMenu}
      tableRender={<TableObjects {...props} />}
      cardRender={<CardsObjects {...props} menuItem={menuItem} />}
      filterDefaultValues={{ FunnelStepIds: '200,300,400,500,600,700' }}
      hasSearchVariants={false}
      searchBy="QueryWithFid"
      hasExport
    />
  );
};
