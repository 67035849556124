/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { FormTab, FormDataConsumer, useNotify } from 'react-admin';
import { Link } from '@material-ui/core';

import { useFormState } from 'react-final-form';
import { TextInput } from '../../TextInput/TextInput';
import { NumberInput, BooleanInput, TabbedForm, Map, RichText } from '../../common';

import {
  validates,
  checkRoleAdminOrSupervisor,
  isObject,
  // getFormattedDate,
} from '../../../utils';
import { SeoTab } from '../../formTabs';
import { FormRow } from '../../FormRow';
import { ConstantSelectB24 as ConstantSelect, AutocompleteWithFormRow } from '../../app';
import {
  publicationStatuses,
  currencyTypes,
  objectContractTypes,
  luxuryClasses,
  yandexVas,
  avitoListingFee,
  // cianExcludedService,
  // cianService,
  heatingType,
  repairType,
  expoTypes,
  leaseDeposit,
  liquidityTypes,
  initialSales,
  personTypes,
  windowViewTypes,
  utilityMeters,
  landStatuses,
  vatTypes,
  commercialTypes,
} from '../../../constants';

import { ExpertSelect, OwnerSelect, RepresentativeSelect } from '../../RASelects';
import { ListImagesOrVideos } from '../../additionalLists';
import { DateTimePickerInput } from '../../RAInputs';
import { СustomNumericInput } from '../Contracts';
import { UnpublishObjectDialog } from '../../dialogs';
import {
  commercialClasses,
  commercialEntranceTypes,
  commercialMainPurposes,
  commercialPurposes,
  commercialStates,
  // getFunnelSteps,
  residentialBathroomTypes,
  residentialBathTypes,
  residentialContractTypes,
  residentialDealTypes,
  residentialOwnershipTypes,
  residentialParkingTypes,
  residentialRoomTypes,
  residentialTransportTypes,
  yuCommercialTypes,
} from '../../../constants/yucrm';
import { MarketingStats } from '../Objects/Objects';
// import { MyYMap } from '../../common/YMap';

const minOldPriceValue = 1000000;

const checkIsPlot = (formData) => {
  let isPlot = false;

  if (
    Array.isArray(formData?.objectTypes) &&
    Number(formData.objectTypes[0]?.objectTypeId) === 100
  ) {
    isPlot = true;
  }

  if (
    isObject(formData?.objectTypes) &&
    Number(formData.objectTypes.current[0]?.objectTypeId) === 100
  ) {
    isPlot = true;
  }

  return isPlot;
};

const tabbedFormTabsPropsDefault = { orientation: 'vertical' };

const FormState = ({ id }) => {
  const formstate = useFormState();
  const dirtyRef = useRef(false);

  useEffect(() => {
    const newModified = { ...formstate.modified };
    delete newModified.images;
    delete newModified.videos;
    delete newModified.lat;
    delete newModified.lng;

    if (Object.values(newModified).some((value) => value) && !dirtyRef.current) {
      dirtyRef.current = true;
      window.parent.postMessage(
        { type: 'v1ItemInputStarted', value: { type: 'RealtyObjects', id } },
        '*',
      );
    }
  });

  return null;
};

export const FormObjectsB24 = (props) => {
  const { permissions, location, id, authId, record } = props;
  const notify = useNotify();
  const [visibleDescriptionPdf, setVisibleDescriptionPdf] = useState(false);

  useEffect(() => {
    // fix for map!
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  // const hasRoleAdmin = useMemo(() => {
  //   return checkRoleAdmin(permissions);
  // }, [permissions]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const disableEdit = useMemo(() => {
    if (hasRoleAdminOrSupervisor || !authId) {
      return false;
    }

    if (record && record.manager && record.manager.authId && authId === record.manager.authId) {
      return false;
    }

    return true;
  }, [authId, hasRoleAdminOrSupervisor, record]);

  const [type, setType] = useState('');

  const handleBlurOldPrice = useCallback(
    (event) => {
      const oldPriceValue = event.target.value;

      if (oldPriceValue && oldPriceValue < minOldPriceValue) {
        setTimeout(() => {
          notify(`Вы уверены, что цена меньше миллиона?`, { type: 'info' });
        }, 200);
      }
    },
    [notify],
  );

  // const [baseObject, setBaseObject] = useState(record.realtyBaseObject);

  // const [sector, setSector] = useState();
  // const handleChangeSector = useCallback((newValue) => {
  //   const fetch = async () => {
  //     setSector();
  //     const result = await dataProvider.getOne('RealtySectors', { id: newValue?.id });
  //     if (result.data) {
  //       setSector(result.data);
  //     }
  //   };
  //   if (newValue?.id) {
  //     fetch();
  //   }

  //   if (!newValue) {
  //     setSector();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (record.realtySector) {
  //     setSector(record.realtySector);
  //   }
  // }, [record]);

  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  const oldSysStatusRef = useRef(record.sysStatus);

  const handleChangeSysStatus = useCallback(
    (event) => {
      const newValue = event.target.value;
      if (record.id && Number(oldSysStatusRef.current) === 200 && Number(newValue) === 100) {
        handleOpenDialog();
      }
      oldSysStatusRef.current = newValue;
    },
    [handleOpenDialog, record],
  );

  // eslint-disable-next-line no-unused-vars
  const [externalExpertDirectionId, setExternalExpertDirectionId] = useState();
  const [expertDirection, setExpertDirection] = useState(record.expertSalesDirectionId);

  const handleChangeExpert = useCallback(
    (newValue) => {
      let resultValue;
      if (newValue?.salesDirectionId && !expertDirection) {
        resultValue = newValue.salesDirectionId;
      }
      setExternalExpertDirectionId(resultValue);
    },
    [expertDirection],
  );

  const handleChangeExpertDirectionId = useCallback((newValue) => {
    setExpertDirection(newValue);
  }, []);

  return (
    <TabbedForm {...props} hideSave={disableEdit} tabbedFormTabsProps={tabbedFormTabsPropsDefault}>
      {/*  */}
      <FormTab label="Основное">
        <FormState id={id} />
        {id && (
          <FormRow>
            <TextInput source="id" label="idXO" disabled variant="standard" />
          </FormRow>
        )}
        <FormRow>
          <TextInput
            source="friendlyId"
            label="Номер лота"
            validate={validates.friendlyId}
            variant="standard"
          />
        </FormRow>
        <ExpertSelect
          {...props}
          onChange={handleChangeExpert}
          componentProps={{
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <AutocompleteWithFormRow
          {...props}
          source="expertSalesDirection"
          resourceName="SalesDirections"
          resourceId="expertSalesDirectionId"
          onChange={handleChangeExpertDirectionId}
          validate={validates.required}
          componentProps={{
            label: 'Направление эксперта',
            externalValue: externalExpertDirectionId,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <OwnerSelect
          {...props}
          componentProps={{
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <RepresentativeSelect
          {...props}
          componentProps={{
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <ConstantSelect
            source="sysStatus"
            objectChoices={publicationStatuses}
            label="Статус публикации"
            defaultValue={100}
            onChange={handleChangeSysStatus}
            variant="standard"
          />
        </FormRow>
        {/* <FormRow>
          <ConstantSelect
            source="funnelStep"
            objectChoices={getFunnelSteps(hasRoleAdminOrSupervisor)}
            label="Этап воронки"
            allowEmpty
            validate={validates.required}
            variant="standard"
          />
        </FormRow> */}
        <FormRow maxWidth={800}>
          <TextInput source="comment" label="Комментарий" multiline rows={6} variant="standard" />
        </FormRow>
        <FormRow>
          <BooleanInput label="Для продажи" source="isForSale" variant="standard" />
        </FormRow>
        <FormRow>
          <BooleanInput label="Для аренды" source="isForRent" />
        </FormRow>
        <FormRow>
          <DateTimePickerInput
            source="lastPreSoldDate"
            label="Дата продажи"
            helperText="Когда в последний раз продан по мнению экспертов"
            inputVariant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            source="soldPriceRub"
            label="Цена за которую продан"
            helperText="Вводить в рублях!"
            min={0}
            variant="standard"
          />
        </FormRow>
      </FormTab>

      <FormTab label="Адрес">
        <AutocompleteWithFormRow
          {...props}
          source="realtyGroup"
          resourceName="RealtyGroups"
          resourceId="realtyGroupId"
          // validate={validates.objectVillage}
          componentProps={{
            label: 'Комплекс',
            fullDynamic: 'NameContains',
            getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <AutocompleteWithFormRow
          {...props}
          source="ownSalesDirection"
          resourceName="SalesDirections"
          resourceId="ownSalesDirectionId"
          validate={validates.required}
          componentProps={{
            label: 'Направление',
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <AutocompleteWithFormRow
          {...props}
          source="realtyGroupGeoTags"
          resourceName="GeoTags"
          resourceId="geoTagId"
          additionalSource="geoTag"
          componentProps={{
            label: 'Локация',
            disabled: true,
            multiple: true,
            filterSelectedOptions: true,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <NumberInput
            min={0}
            source="distanceToMkad"
            label="Расстояние до МКАД"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.address' : 'address'}
            label={record.realtyGroupId ? 'Адрес поселка' : 'Адрес'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.addressPostalIndex' : 'addressPostalIndex'}
            label={record.realtyGroupId ? 'Индекс поселка' : 'Индекс'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.addressCountry' : 'addressCountry'}
            label={record.realtyGroupId ? 'Страна поселка' : 'Страна'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.addressRegion' : 'addressRegion'}
            label={record.realtyGroupId ? 'Регион/Область поселка' : 'Регион/Область'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.addressLocality' : 'addressLocality'}
            label={record.realtyGroupId ? 'Город поселка' : 'Город'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.addressStreet' : 'addressStreet'}
            label={record.realtyGroupId ? 'Улица поселка' : 'Улица'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput source="addressHouseNo" label="Дом" variant="standard" />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.addressBlock' : 'addressBlock'}
            label={record.realtyGroupId ? 'Корпус поселка' : 'Корпус'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source={record.realtyGroupId ? 'realtyGroup.addressBuilding' : 'addressBuilding'}
            label={record.realtyGroupId ? 'Строение поселка' : 'Строение'}
            disabled={Boolean(record.realtyGroupId)}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput label="Номер квартиры" source="residentialFlatNumber" variant="standard" />
        </FormRow>
        <Map {...props} variant="standard" />
        <FormRow>
          <BooleanInput
            label="Скрыть координаты"
            source="isHideAnonCoords"
            helperText="Отображать координаты комплекса"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="cadastralNumber"
            label="Кадастровый номер объекта"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="anyJsonDictionary.lotCadastralNumber"
            label="Кадастровый номер участка"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialTsHubReachWay"
            objectChoices={residentialTransportTypes}
            label="Как добраться до станции метро"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="residentialTsHubNearest"
            label="Жд станция или метро"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="residentialTsHubReachMinutes"
            label="Расстояние до станции метро, мин"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialSeaReachWay"
            objectChoices={residentialTransportTypes}
            label="Как добраться до моря"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="residentialSeaReachMinutes"
            label="Расстояние до моря, мин"
            variant="standard"
          />
        </FormRow>
      </FormTab>

      <FormTab label="Об объекте">
        <AutocompleteWithFormRow
          {...props}
          source="objectTypes"
          resourceName="RealtyObjectTypes"
          resourceId="objectTypeId"
          additionalSource="objectType"
          componentProps={{
            label: 'Тип объекта',
            multiple: true,
            filterSelectedOptions: true,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <ConstantSelect
            source="isInitialSale"
            objectChoices={initialSales}
            label="Первичка или вторичка?"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="luxuryClass"
            objectChoices={luxuryClasses}
            label="Класс объекта"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="rooms" label="Кол-во комнат" variant="standard" />
        </FormRow>
        <AutocompleteWithFormRow
          {...props}
          source="realtyObjectRoomsTypes"
          resourceName="RealtyObjectRoomsTypes"
          resourceId="realtyObjectRoomsTypeId"
          additionalSource="realtyObjectRoomsType"
          componentProps={{
            label: 'Тип комнат',
            multiple: true,
            filterSelectedOptions: true,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <ConstantSelect
            source="residentialRoomType"
            objectChoices={residentialRoomTypes}
            label="Тип планировки"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={1}
            source="floorsInBuilding"
            label="Количество этажей в здании"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput min={1} source="floors" label="Этажей лота" variant="standard" />
        </FormRow>
        <FormRow>
          <NumberInput min={1} source="floor" label="Этаж лота" variant="standard" />
        </FormRow>
        <FormRow>
          <TextInput
            source="totalFoorArea"
            label="Общая площадь"
            InputProps={{
              endAdornment: (
                <span>
                  м<sup style={{ fontSize: '10px' }}>2</sup>
                </span>
              ),
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="usefulFoorArea"
            label="Жилая площадь"
            InputProps={{
              endAdornment: (
                <span>
                  м<sup style={{ fontSize: '10px' }}>2</sup>
                </span>
              ),
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="kitchenArea"
            label="Площадь кухни"
            InputProps={{
              endAdornment: (
                <span>
                  м<sup style={{ fontSize: '10px' }}>2</sup>
                </span>
              ),
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="roomsArea"
            label="Площадь комнат"
            helperText="Например: «18.5-14-15»"
            variant="standard"
          />
        </FormRow>
        <AutocompleteWithFormRow
          {...props}
          source="currentObjectState"
          resourceName="RealtyObjectStates"
          resourceId="currentObjectStateId"
          componentProps={{
            label: 'Состояние',
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <ConstantSelect
            source="repairType"
            objectChoices={repairType}
            label="Ремонт"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="windowView"
            objectChoices={windowViewTypes}
            label="Вид из окон"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput min={1} source="loggias" label="Кол-во лоджий" variant="standard" />
        </FormRow>
        <FormRow>
          <NumberInput min={1} source="balconies" label="Кол-во балконов" variant="standard" />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialBathroomType"
            objectChoices={residentialBathroomTypes}
            label="Сан.узел"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <AutocompleteWithFormRow
          {...props}
          source="communications"
          resourceName="RealtyObjectCommunications"
          resourceId="realtyObjectCommunicationId"
          additionalSource="realtyObjectCommunication"
          componentProps={{
            label: 'Коммуникации',
            multiple: true,
            filterSelectedOptions: true,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <TextInput source="residentialCellHeight" label="Высота потолка" variant="standard" />
        </FormRow>
        <AutocompleteWithFormRow
          {...props}
          source="objectFeatures"
          resourceName="RealtyObjectFeatures"
          resourceId="objectFeatureId"
          additionalSource="objectFeature"
          componentProps={{
            label: 'Преимущества',
            multiple: true,
            filterSelectedOptions: true,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <BooleanInput label="Консьерж" source="residentialIsConcierge" />
        </FormRow>
        <FormRow>
          <BooleanInput
            label="Можно курить"
            source="anyJsonDictionary.smokingAllowed"
            helperText="smokingAllowed"
          />
        </FormRow>
        <FormRow>
          <BooleanInput
            label="Можно детей"
            source="anyJsonDictionary.childrenAllowed"
            helperText="childrenAllowed"
          />
        </FormRow>
        <FormRow>
          <BooleanInput
            label="Можно животных"
            source="anyJsonDictionary.petsAllowed"
            helperText="petsAllowed"
          />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="bedrooms" label="Спальни" variant="standard" />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="masterBeds" label="Мастер-спальни" variant="standard" />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="wardrobes" label="Гардеробные" variant="standard" />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="wardrobeInBeds"
            label="Гардеробные в спальнях"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="bathrooms" label="Ванные" variant="standard" />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="bathInBeds" label="Ванные в спальнях" variant="standard" />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="lavatories" label="Туалеты" variant="standard" />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialBathType"
            objectChoices={residentialBathTypes}
            label="Ванна"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput min={0} source="terraces" label="Количество террас" variant="standard" />
        </FormRow>
        <FormRow>
          <TextInput
            source="plottage"
            label="Площадь участка"
            InputProps={{
              endAdornment: <span>сот.</span>,
            }}
            variant="standard"
          />
        </FormRow>
        <AutocompleteWithFormRow
          {...props}
          source="terrainTags"
          resourceName="TerrainTags"
          resourceId="terrainTagId"
          additionalSource="terrainTag"
          componentProps={{
            label: 'Особенности участка',
            multiple: true,
            filterSelectedOptions: true,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <BooleanInput
            source="isPmg"
            label="Возможность ПМЖ"
            helperText="pmg"
            defaultValue={true}
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="anyJsonDictionary.landStatus"
            objectChoices={landStatuses}
            label="Статус земли"
            helperText="Status"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="anyJsonDictionary.commercialType"
            objectChoices={commercialTypes}
            label="Типы коммерческой недвижимости"
            allowEmpty
            helperText="CommercialType"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="commercialType"
            objectChoices={yuCommercialTypes}
            label="Тип"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput label="кВт" source="commercialElectroPower" min={0} variant="standard" />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="commercialPurpose"
            objectChoices={commercialPurposes}
            label="Назначение здания"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="commercialEntranceType"
            objectChoices={commercialEntranceTypes}
            label="Тип входа"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="commercialMainPurpose"
            objectChoices={commercialMainPurposes}
            label="Основное назначение"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="commercialState"
            objectChoices={commercialStates}
            label="Состояние"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="commercialClass"
            objectChoices={commercialClasses}
            label="Класс здания"
            allowEmpty
            variant="standard"
          />
        </FormRow>
      </FormTab>
      <FormTab label="О здании">
        <FormRow>
          <NumberInput min="0" source="year" label="Год постройки" variant="standard" />
        </FormRow>
        <AutocompleteWithFormRow
          {...props}
          source="realtyObjectStyle"
          resourceName="RealtyObjectStyles"
          resourceId="realtyObjectStyleId"
          componentProps={{
            label: 'Архитектурный стиль',
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <AutocompleteWithFormRow
          {...props}
          source="objectMaterials"
          resourceName="RealtyObjectMaterials"
          resourceId="objectMaterialId"
          additionalSource="objectMaterial"
          componentProps={{
            label: 'Материалы',
            multiple: true,
            filterSelectedOptions: true,
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <NumberInput
            min={0}
            source="residentialElevators"
            label="Пассажирских лифтов"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="residentialFreightElevators"
            label="Грузовых лифтов"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <BooleanInput label="Мусоропровод" source="residentialIsGarbageChute" />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialParkingType"
            objectChoices={residentialParkingTypes}
            label="Парковка"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="residentialBaseFloorCellHeight"
            label="Высота 1-го этажа"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="residentialLastMajorRepair"
            label="Кап.ремонт"
            variant="standard"
          />
        </FormRow>
      </FormTab>

      <FormTab label="Изображения">
        <ListImagesOrVideos
          id={id}
          hasMultiple
          parentType={type}
          hasTemplateName
          variant="standard"
        />
      </FormTab>

      <FormTab label="Видео">
        <ListImagesOrVideos id={id} isVideo parentType={type} variant="standard" />
      </FormTab>

      <FormTab label="Описание">
        <FormRow>
          <TextInput
            source="name"
            label="Название"
            validate={validates.nameObject}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="shortName"
            label="Краткое название"
            validate={validates.shortNameObject}
            variant="standard"
          />
        </FormRow>
        <FormRow maxWidth={800}>
          <RichText source="description" variant="standard" />
        </FormRow>
        <FormRow maxWidth={800}>
          <TextInput
            source="shortDescription"
            label="Описание под заголовком"
            multiline
            rows={6}
            validate={validates.shortDescriptionObject}
            variant="standard"
          />
        </FormRow>
        <FormRow maxWidth={800}>
          <Link
            color="primary"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setVisibleDescriptionPdf((visible) => !visible);
            }}
          >
            Добавить описание для презентации
          </Link>
        </FormRow>
        {visibleDescriptionPdf && (
          <FormRow maxWidth={800}>
            <TextInput
              source="pdfDescription"
              label="Описание для презентации"
              multiline
              rows={6}
              validate={validates.pdfDescriptionObject}
              variant="standard"
            />
          </FormRow>
        )}
        <FormRow>
          <TextInput
            source="sortOrder"
            label="Порядок сортировки"
            type="number"
            defaultValue={1000}
            disabled={!hasRoleAdminOrSupervisor}
            variant="standard"
          />
        </FormRow>
        <SeoTab variant="standard" />
      </FormTab>
      <FormTab label="Условия сделки">
        <FormRow>
          <NumberInput
            source="oldPrice"
            label="Цена продажи"
            onBlur={handleBlurOldPrice}
            InputProps={{
              inputComponent: СustomNumericInput,
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            source="oldRentPrice"
            label="Цена аренды"
            InputProps={{
              inputComponent: СustomNumericInput,
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="currencyType"
            objectChoices={currencyTypes}
            label="Валюта"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="feePercents"
            label="Комиссия за продажу"
            validate={validates.feePercents}
            InputProps={{
              endAdornment: '%',
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="contractType"
            defaultValue={300}
            objectChoices={objectContractTypes}
            label="Тип договора"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <DateTimePickerInput
            source="contractUntil"
            label="Срок действия договора"
            inputVariant="standard"
          />
        </FormRow>
        <FormRow>
          <BooleanInput label="Доверенность на агента" source="residentialIsAgentTrusted" />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="expoType"
            defaultValue={100}
            objectChoices={expoTypes}
            label="Тип продажи"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialDealType"
            allowEmpty
            objectChoices={residentialDealTypes}
            label="Тип сделки"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialOwnershipType"
            objectChoices={residentialOwnershipTypes}
            label="Тип собственности"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="residentialContractType"
            objectChoices={residentialContractTypes}
            label="Форма договора"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <BooleanInput label="Есть обременение" source="commercialIsEncumbered" />
        </FormRow>
        <FormRow>
          <BooleanInput label="Переуступка" source="residentialIsRightsShifted" />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="anyJsonDictionary.PersonType"
            objectChoices={personTypes}
            label="Тип лица"
            allowEmpty
            helperText="PersonType"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="residentialRosreestrId"
            label="Номер регистрации в Росреестре"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="liquidity"
            objectChoices={liquidityTypes}
            label="Тип ликидности"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormDataConsumer>
          {({ formData }) => {
            const liquidity = formData.liquidity;
            if (!liquidity) {
              delete formData.liquidityReasons;
            }

            return (
              liquidity && (
                <AutocompleteWithFormRow
                  {...props}
                  source="liquidityReasons"
                  resourceName="realtyLiquidityReason"
                  resourceId="realtyLiquidityReasonId"
                  additionalSource="realtyLiquidityReason"
                  componentProps={{
                    label: 'Причины ликвидности',
                    multiple: true,
                    filterSelectedOptions: true,
                    hasViewLink: false,
                    variant: 'standard',
                  }}
                />
              )
            );
          }}
        </FormDataConsumer>
        <FormRow>
          <NumberInput
            source="anyJsonDictionary.otherUtilitiesPayment"
            label="Плата за другие ЖКУ в месяц"
            helperText="otherUtilitiesPayment"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="anyJsonDictionary.deposit"
            label="Залог собственнику"
            helperText="Deposit"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            max={999999}
            source="anyJsonDictionary.depositAmount"
            label="Сумма залога"
            helperText="depositAmount"
            validate={validates.depositAmount}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="anyJsonDictionary.utilityMeters"
            objectChoices={utilityMeters}
            label="Оплата по счетчикам"
            helperText="utilityMeters"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="anyJsonDictionary.otherUtilities"
            objectChoices={utilityMeters}
            label="Другие ЖКУ"
            helperText="otherUtilities"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="anyJsonDictionary.leaseDeposit"
            objectChoices={leaseDeposit}
            label="Залог"
            helperText="leaseDeposit"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="clientFee"
            label="Комиссия от прямого клиента"
            helperText="ClientFee"
            defaultValue={0}
            InputProps={{
              endAdornment: '%',
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="agentFee"
            label="Комиссия от другого агента"
            helperText="AgentFee"
            defaultValue={0}
            InputProps={{
              endAdornment: '%',
            }}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            source="anyJsonDictionary.leaseCommissionSize"
            label="Размер комиссии"
            max={200}
            InputProps={{
              endAdornment: '%',
            }}
            helperText="leaseCommissionSize"
            validate={validates.leaseCommissionSize}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="anyJsonDictionary.agentBonus"
            label="Бонус агенту"
            helperText="AgentBonus"
            variant="standard"
          />
        </FormRow>
      </FormTab>
      <FormTab label="Теги марк.">
        <FormRow>
          <BooleanInput label="Цена снижена" source="isDiscounted" variant="standard" />
        </FormRow>
        <FormRow>
          <BooleanInput label="Спецпредложение" source="isWowOffer" variant="standard" />
        </FormRow>
        <FormRow>
          <BooleanInput label="Уникальный объект" source="isUnique" variant="standard" />
        </FormRow>
        <FormRow>
          <BooleanInput
            label="Новый"
            source="isNew"
            helperText="Автоматическое поле, чтобы изменить надо использовать поле ниже"
            disabled
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <BooleanInput
            label="Новый (управляемый)"
            source="isOverridedNew"
            helperText="Перезаписывает поле новый (для продления флага новый)"
          />
        </FormRow>
        <FormRow>
          <BooleanInput label="Важный" source="isImportant" />
        </FormRow>
      </FormTab>
      <FormTab label="Фиды">
        <AutocompleteWithFormRow
          {...props}
          source="realtyFeeds"
          resourceName="RealtyFeeds"
          resourceId="realtyFeedId"
          additionalSource="realtyFeed"
          componentProps={{
            label: 'Площадки фидов',
            multiple: true,
            filterSelectedOptions: true,
            providerParams: { filter: { IsSystem: true, Status: 200 } },
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormRow>
          <BooleanInput
            format={(v) => !v}
            parse={(v) => !v}
            label="Использовать в фидах"
            source="excludeFromFeeds"
            defaultValue={true}
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="anyJsonDictionary.vatType"
            objectChoices={vatTypes}
            label="Тип НДС"
            helperText="vatType"
            defaultValue={100}
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <TextInput
            source="anyJsonDictionary.CplModeration"
            label="CPL модерация"
            helperText="CplModeration. Заполняются данными дольщика из ДДУ. Для физического лица необходимо указать ФИО, для юридического лица — ИНН."
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="heatingType"
            objectChoices={heatingType}
            label="Отопление"
            helperText="HeatingType"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="auctionBet"
            label="Ставка на объявление"
            helperText="Auction	Bet"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="avitoListingFee"
            objectChoices={avitoListingFee}
            label="Вариант платного размещения"
            helperText="ListingFee"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <ConstantSelect
            source="yandexVas"
            objectChoices={yandexVas}
            label="Дополнительная опция по продвижению объявления"
            helperText="vas"
            allowEmpty
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <DateTimePickerInput
            source="yandexVasDate"
            label="Дата для продвижения объявления"
            helperText="vasDate"
            inputVariant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            source="anyJsonDictionary.HouseID"
            label="ID Корпус"
            helperText="HouseID"
            variant="standard"
          />
        </FormRow>
        <FormRow>
          <NumberInput
            min={0}
            source="anyJsonDictionary.newDevelopmentId"
            label="ID новостройки"
            helperText="NewDevelopmentId, https://autoload.avito.ru/format/New_developments.xml"
            variant="standard"
          />
        </FormRow>
        {id && <MarketingStats id={id} />}
      </FormTab>
      <FormTab label="Дополнительно">
        <AutocompleteWithFormRow
          {...props}
          source="realtyBaseObjects"
          resourceName="RealtyBaseObjects"
          resourceId="realtyBaseObjectId"
          additionalSource="realtyBaseObject"
          // onChange={(newBaseObject) => {
          //   setBaseObject(newBaseObject);
          // }}
          componentProps={{
            label: 'Базовые объекты',
            multiple: true,
            filterSelectedOptions: true,
            fullDynamic: 'NameContains',
            hasViewLink: false,
            variant: 'standard',
          }}
        />
        <FormDataConsumer>
          {({ formData }) => {
            const villageId = formData.realtyGroupId;
            if (!villageId) {
              delete formData.realtySector;
              delete formData.realtySectorId;
            }

            return (
              villageId && (
                <AutocompleteWithFormRow
                  {...props}
                  source="realtySector"
                  resourceName="RealtySectors"
                  resourceId="realtySectorId"
                  componentProps={{
                    label: 'Сектор',
                    providerParams: { filter: { RealtyGroup: villageId } },
                    hasViewLink: false,
                    variant: 'standard',
                  }}
                />
              )
            );
          }}
        </FormDataConsumer>
        <FormRow>
          <BooleanInput label="Проживает собственник" source="isOwnerInside" />
        </FormRow>
        <FormRow>
          <BooleanInput label="Ключи в офисе продаж" source="isKeysInOffice" />
        </FormRow>
        <FormRow>
          <BooleanInput label="Показы согласовывать заранее" source="isCoordinatedDemo" />
        </FormRow>
        <FormRow>
          <BooleanInput
            label="Онлайн-показ"
            helperText="SafeDemonstration"
            source="safeDemonstration"
          />
        </FormRow>

        <FormDataConsumer>
          {({ formData }) => {
            const isPlot = checkIsPlot(formData);
            setType(isPlot ? 'plot' : '');
          }}
        </FormDataConsumer>
      </FormTab>
      {/*  */}
      <UnpublishObjectDialog changeOpenRef={changeOpenRef} />
    </TabbedForm>
  );
};
