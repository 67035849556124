export const yuCommercialTypes = {
  100: 'Встроенное помещение',
  200: 'Бизнес-центр',
  300: 'Отдельно стоящее здание',
  400: 'Торговый центр',
  500: 'Складской комплекс',
  600: 'Коммерческая земля',
};

export const commercialMainPurposes = {
  100: 'Офис',
  200: 'Производство',
  300: 'Склад',
  400: 'Общепит',
  500: 'Торговая площадь',
  600: 'Выставка',
  700: 'Бытовые услуги',
  800: 'Банк',
  900: 'ИЖС',
  1000: 'САД',
  1100: 'ЛПХ',
  1200: 'ФЕР',
  1300: 'Автобизнес',
  1400: 'Гостиничный бизнес',
  1500: 'Сельхоз назначение',
  1600: 'Под деловую застройку',
  1700: 'Под жилую застройку',
  1800: 'Свободное назначение',
};

export const commercialStates = {
  100: 'Нормальное',
  200: 'Хорошее',
  300: 'Отличное',
  400: 'Произведен ремонт',
  500: 'Евростандарт',
  600: 'Удовлетворительное',
  700: 'Требуется косм. ремонт',
  800: 'Требуется ремонт',
  900: 'Плохое',
  1000: 'Новый без отделки',
  1100: 'Новый с отделкой',
};

export const commercialEntranceTypes = {
  100: 'Отдельный с улицы',
  200: 'Отдельный со двора',
  300: 'Общий с улицы',
  400: 'Общий со двора',
  500: 'Через проходную',
  600: 'Общий с жилыми с улицы',
  700: 'Общий с жилыми со двора',
  800: 'Несколько вкл. отд.',
  900: 'Несколько, все общ.',
};

export const commercialClasses = {
  100: 'A',
  200: 'A-',
  300: 'A+',
  400: 'B',
  500: 'B+',
  600: 'B-',
  700: 'C',
  800: 'C-',
  900: 'D',
};

export const commercialPurposes = {
  100: 'Административное здание',
  200: 'Аутлет',
  300: 'Бизнес-квартал',
  400: 'Бизнес-парк',
  500: 'Бизнес-центр',
  600: 'Деловой дом',
  700: 'Деловой квартал',
  800: 'Деловой центр',
  900: 'Другое',
  1000: 'Жилой дом',
  1100: 'Жилой комплекс',
  1200: 'Имущественный комплекс',
  1300: 'Индустриальный парк',
  1400: 'Логистический комплекс',
  1500: 'Логистический парк',
  1600: 'Логистический центр',
  1700: 'Многофункциональный комплекс',
  1800: 'Модульное здание',
  1900: 'Объект свободного назначения',
  2000: 'Особняк',
  2100: 'Отдельно стоящее здание',
  2200: 'Офисно-гостиничный комплекс',
  2300: 'Офисно-жилой комплекс',
  2400: 'Офисно-производственный комплекс',
  2500: 'Офисно-складское здание',
  2600: 'Офисно-складской комплекс',
  2700: 'Офисное здание',
  2800: 'Офисный квартал',
  2900: 'Офисный комплекс',
  3000: 'Офисный центр',
  3100: 'Производственно-складской комплекс',
  3200: 'Производственное здание',
  3300: 'Производственный комплекс',
  3400: 'Производственный цех',
  3500: 'Промплощадка',
  3600: 'Склад',
  3700: 'Складской комплекс',
  3800: 'Специализированный торговый центр',
  3900: 'Старый фонд',
  4000: 'Технопарк',
  4100: 'Торгово-выставочный комплекс',
  4200: 'Торгово-деловой комплекс',
  4300: 'Торгово-общественный центр',
  4400: 'Торгово-офисный комплекс',
  4500: 'Торгово-развлекательный центр',
  4600: 'Торгово-складской комплекс',
  4700: 'Торговый дом',
  4800: 'Торговый комплекс',
  4900: 'Торговый центр',
};

export const residentialContractTypes = {
  100: 'Собственность',
  200: 'ЖСК',
  300: 'ДДУ (214 ФЗ)',
  400: 'Уступка с ЖСК',
  500: 'Уступка с ДДУ 214',
  600: 'Уступка с ПДКП',
  700: 'ПДКП',
  800: 'ДКП',
  900: 'Подача на собственность',
  1000: 'Предварительный ДДУ',
  1100: 'Уступка с ДДУ (39 ФЗ)',
  1200: 'ДДУ (39 ФЗ)',
  1300: 'Другое',
  1400: 'Уступка с ДДУ/Прямой ДДУ',
};

export const residentialOwnershipTypes = {
  100: 'ЖСК',
  200: 'ЖЭК',
  300: 'Приват',
  400: 'Ведомственная',
  500: 'Частная',
  600: 'Аренда',
  700: 'ДДУ',
};

export const residentialBathTypes = {
  100: 'Отдельная',
  200: 'Совмещенная',
  300: 'Нет ванны',
  400: 'На кухне',
  500: 'Душ',
  600: 'Сидячая',
  700: 'В коридоре',
  800: 'Продольная',
  900: 'Поперечная',
};

export const residentialBathroomTypes = {
  100: 'Раздельный',
  200: 'Совмещенный',
  300: 'Нет ванны',
  400: 'На кухне',
  500: 'Душ',
  600: 'Два смежных',
  700: 'Три смежных',
  800: 'Два раздельных',
  900: 'Три раздельных',
  1000: '1,5',
  1100: '2+2',
};

export const residentialRoomTypes = {
  100: 'Изолированная',
  200: 'Смежно-изолированная',
  300: 'Смежная',
  400: 'Распашонка',
  500: 'Кабинетная (комм.)',
  600: 'Коридорная (комм.)',
  700: 'Смешанная (комм.)',
  800: 'Открытая (комм.)',
};

export const residentialParkingTypes = {
  100: 'Открытая во дворе',
  200: 'Подземный паркинг',
  300: 'Многоуровневый паркинг',
  400: 'Гараж',
  500: 'Отсутствует',
  600: 'Встроенный гараж',
  700: 'Охраняемая',
  800: 'Собственная во дворе',
  900: 'Наземная',
  1000: 'Открытая',
  1100: 'На крыше',
};

export const residentialDealTypes = {
  100: 'Альтернатива',
  200: 'Переуступка',
  300: 'Аукцион',
  400: 'Прямая',
};

export const residentialTransportTypes = {
  100: 'Пешком',
  200: 'На транспорте',
  300: 'На машине',
};

export const getFunnelSteps = (isAdmin) => {
  const values = {
    200: 'В процессе подписания',
    300: 'Подписан',
    400: 'В рекламе',
    500: 'Бронь/Аванс/Задаток',
    600: 'Сделка',
    700: 'Комиссия получена',
    800: 'Отложен',
    900: 'Продан с VE',
    1000: 'Продан через агентство',
    1100: 'Продал сам',
    1200: 'Передумал',
  };

  if (isAdmin) {
    values[100] = 'Неразобранные исторические данные';
  }

  return values;
};
