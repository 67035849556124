import React, { useMemo, useCallback } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import {
  ListItemExclusiveStatus,
  ListItemImportantStatus,
  ListItemRentStatus,
  ListItemSellStatus,
  ListItemSoldStatus,
} from '../../ListItemStatuses';
import { getFormattedNumber, getFullName, getMillions, getObjectLink } from '../../../../utils';
import { Avatar } from '../../../common';
import { GoodSwitch } from '../../Switches';
import { Button } from '../../../buttons';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
  buttonsItem: {
    padding: '4px 12px',
    borderLeft: '1px solid #E0E0E0',

    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 0,
    },

    '&:last-child': {
      paddingRight: 0,
    },
  },
  linkField: {
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '4px',
    marginBottom: '4px',
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '13px',
    display: 'inline-flex',
  },
}));

export const ListItemObject = ({
  record,
  depthKey,
  originalRecord,
  hasButtons,
  page,
  onChangeItems,
}) => {
  const classes = useStyles();

  const imagePath = useMemo(() => {
    if (record?.images?.length > 0) {
      const foundItem = record.images.find((image) => {
        return image.imageType === 200;
      });

      return foundItem?.path || record.images[0].path;
    }
  }, [record]);

  const descriptions = [];

  const contentStatuses = [];

  if (record.idYu) {
    contentStatuses.push(`YUcrm`);
  }

  if (record.ownSalesDirection?.name) {
    contentStatuses.push(`${record.ownSalesDirection.name}`);
  }

  if (record.realtyGroup?.name) {
    contentStatuses.push(`${record.realtyGroup.name}`);
  }

  if (record.currentPriceInRub) {
    descriptions.push(`${getFormattedNumber(getMillions(record.currentPriceInRub))} (М/₽)`);
  }

  if (record.currentRentPriceInRub) {
    descriptions.push(`${getFormattedNumber(record.currentRentPriceInRub)} (₽/мес.)`);
  }

  if (record.totalFoorArea) {
    descriptions.push(`${record.totalFoorArea} (м2)`);
  }

  if (record.plottage) {
    descriptions.push(`${record.plottage} (сот.)`);
  }

  if (record.distanceToMkad) {
    descriptions.push(`${record.distanceToMkad} (км. МКАД)`);
  }

  if (record.currentObjectState?.name) {
    descriptions.push(record.currentObjectState.name);
  }

  if (record.owner) {
    contentStatuses.push({ text: getFullName(record.owner), color: 'green' });
  }

  const statuses = [];

  const isSold =
    Number(record.funnelStep) === 600 ||
    Number(record.funnelStep) === 700 ||
    Number(record.funnelStep) === 900 ||
    Number(record.funnelStep) === 1000 ||
    Number(record.funnelStep) === 1100;

  if (isSold) {
    statuses.push(<ListItemSoldStatus />);
  }

  if (!isSold && record.sysStatus !== -100) {
    if (record.currentPrice) {
      statuses.push(<ListItemSellStatus />);
    }

    if (record.currentRentPrice) {
      statuses.push(<ListItemRentStatus />);
    }
  }

  if (record.contractType === 100) {
    statuses.push(<ListItemExclusiveStatus />);
  }

  if (record.isImportant === true) {
    statuses.push(<ListItemImportantStatus />);
  }

  const link = `/RealtyObjects/${record.id}`;

  const handleChange = useCallback(
    (value, name) => {
      const newItem = { ...originalRecord };
      newItem[name] = value;
      newItem.clientStatus = 'edit';
      if (onChangeItems) {
        onChangeItems(newItem);
      }
    },
    [onChangeItems, originalRecord],
  );

  const linkToSite = getObjectLink(record);

  let comment;

  if (page === 'shows') {
    comment = originalRecord.comment;
  }

  return (
    <>
      <ListItemMainBlock
        record={record}
        descriptions={descriptions}
        imagePath={imagePath}
        additionalId="friendlyId"
        statuses={statuses}
        isMenuBottom
        link={link}
        depthKey={depthKey}
        hasTaskStatus
        hasButtons={hasButtons}
        contentStatuses={contentStatuses}
        comment={comment}
      />
      <div className={classes.buttons}>
        {/* {page === 'shows' && (
          <div className={classes.buttonsItem}>
            <DemonstratedSwitch onChange={handleChange} checked={originalRecord.isDemonstrated} />
          </div>
        )} */}
        {page !== 'shows' && linkToSite && (
          <div className={classes.buttonsItem}>
            <Button href={linkToSite} target="_blank" size="small">
              Сайт
            </Button>
          </div>
        )}
        {page === 'shows' && (
          <div className={classes.buttonsItem}>
            <GoodSwitch onChange={handleChange} checked={originalRecord.isGood} />
          </div>
        )}
        {record.manager && (
          <div className={classes.buttonsItem}>
            <Tooltip title={`Эксперт (${getFullName(record.manager)})`} arrow>
              <div>
                <Avatar option={record.manager} source="photo" className={classes.avatar} />
              </div>
            </Tooltip>
          </div>
        )}
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={originalRecord.sortOrder} />
        </div>
      </div>
    </>
  );
};
