import React from 'react';

import { PeopleSelect } from '../People';

export const OwnerSelect = (props) => {
  const { componentProps } = props;

  return (
    <PeopleSelect
      {...props}
      source="owner"
      resourceId="ownerId"
      componentProps={{
        ...componentProps,
        label: 'Собственник',
        fullDynamic: 'AllNames',
        providerParams: { filter: { isOwner: true } },
      }}
    />
  );
};
