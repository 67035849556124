import React, { useMemo } from 'react';
import { SelectInput as RASelectInput, useInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { mergeClasses } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  filled: {
    '& > div:not(.Mui-disabled)': {
      backgroundColor: (props) => {
        if (props.color === 'green') {
          return theme.palette.green.light;
        }

        if (props.color === 'red') {
          return theme.palette.red.light;
        }

        return theme.palette.primaryNew.light;
      },
    },
    '& > div:hover:not(.Mui-disabled):not(.Mui-error)': {
      backgroundColor: (props) => {
        if (props.color === 'green') {
          return theme.palette.green.light100;
        }

        if (props.color === 'red') {
          return theme.palette.red.light100;
        }

        return theme.palette.primaryNew.light100;
      },
    },
  },
}));

export const SelectInput = (props) => {
  const { source, className, filledColors = {} } = props;

  const {
    input: { value },
  } = useInput({
    source,
  });

  const isFilled = useMemo(() => {
    return Boolean(value) || value === false;
  }, [value]);

  const color = useMemo(() => {
    let currentValue = value;
    if (typeof value === 'number') {
      currentValue = parseInt(value, 10);
    }

    if (currentValue in filledColors) {
      return filledColors[currentValue];
    }

    return 'default';
  }, [filledColors, value]);

  const classes = useStyles({ color });

  return (
    <RASelectInput
      helperText=""
      variant="filled"
      {...props}
      className={mergeClasses(classes.root, isFilled && classes.filled, className)}
      InputProps={{ classes: { input: mergeClasses(classes.input) } }}
      id={source}
      fullWidth
      margin="none"
    />
  );
};
